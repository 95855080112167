import * as style  from "../utilities/getStyle";
import * as tag from "./customMetadataConstants";
import * as portalShared from "../shared/metadataConstantsAndUtilities"
import * as font from "../utilities/fontUtilities";


// Display Definition Object contains an array of pages, Pages contains an array of sections, Sections contain an array of Items
// Section = MATERIAL UI GRID container

// Pages are an array of sections

export const OBJ_TYPE = {
    QUESTIONNAIRE: "QNR",
    CATEGORY: "CAT",
    GROUP: "GRP",
    GROUP_LIKERT: "GRP_LIKERT",
    QUESTION: "QUE",
    ANSWER: "ANSWER",
    BUTTON: "BUTTON",
    TITLE: "TITLE",
    DDLB: "DDLB",
};

export const OBJ_TEXT_TYPE = {
    NAME: "NAME",
    DESCRIPTION: "DESCRIPTION",
    START_PAGE_SCRIPT: "START_PAGE_SCRIPT",
    START_PAGE_TEXT: "START_PAGE_TEXT",
    CLOSE_PAGE_SCRIPT: "CLOSE_PAGE_SCRIPT",
    CLOSE_PAGE_TEXT: "CLOSE_PAGE_TEXT",
    LIKERT_TITLES: "LIKERT_TITLES",
    LIKERT_RESPONSIVE_LABELS: "LIKERT_RESPONSIVE_LABELS",
    QUESTION_LABELS: "QUESTION_LABELS"
};

export const BASE = {
    TYPE: "type"
};

export const OBJ = {
    ITEM_ARRAY: "items",
    KEY: {
        DDO: {
            PAGE_ARRAY: "pages", // []
            PAGE_SECTION_ARRAY: "sections", // []
        },
        PAGE: {
            // Pages are an array of section
            SECTION: {
                TYPE: "type",
                CONTAINER: "container",
                DIRECTION: "direction",
                JUSTIFY: "justify",
                SIZE: "size",          // z values = lg, md, sm
                SIZE_DEF: "sizeDef",     // zd value = false | 'auto' | true | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
                SPACING: "spacing",
                WRAP: "wrap",
                ALIGN_CONTENT: "alignContent",
                ALIGN_ITEMS: "alignItems",
                CLASS_TYPE: "classType",
                STYLE_TYPE: "styleType",
                SHOW_NUMBER: "showNumber",
                ITEM_ARRAY: "items", // []
                // Sections are an array of items...
                ITEM: {
                    TITLE: {
                        TYPE: "type",
                        FORMAT: "format",  // Plain, Info, Comment
                        SOURCE: "source",
                        ID: "id",
                        NAME: "name",
                        VARIANT: "variant",
                        SHOW_NUMBER: "showNumber",
                        SHOW_GROUP_KEY: "showGroupKey", // Only used for show group
                        SHOW_GROUP_OBJ_ARRAY: "showGroupObjArray", // Only used for show group
                        OBJ_HAS_DESCRIPTION: "hasDescription" // Groups and titles with description need to render title and description before likert answer titles
                    },
                    BUTTON: {
                        TYPE: "type",
                        NAME: "name",
                        LOCATION: "location",
                        MODE: "mode",
                        SIZE: "size",
                        COLOR: "color",
                        VARIANT: "variant"
                    },
                    PAGE_NUM: {
                        TYPE: "type",
                    },
                    QUESTION: {
                        TYPE: "type",
                        ID: "id",
                        SHOW_GROUP_KEY: "showGroupKey", // Only used for show group
                        SHOW_GROUP_OBJ_ARRAY: "showGroupObjArray", // Only used for show group
                        SHOW_NUMBER: "showNumber",
                    },
                    LIKERT_GROUP: {
                        TYPE: "type",
                        ID: "id",
                        QUESTIONS: "questions" // []
                    },
                    LIKERT_QUESTION: {
                        TYPE: "type",
                        ID: "id",
                        SHOW_GROUP_KEY: "showGroupKey", // Only used for show group
                        SHOW_GROUP_OBJ_ARRAY: "showGroupObjArray", // Only used for show group
                        SHOW_NUMBER: "showNumber",
                    },
                    ICON: {
                        TYPE: "type",
                        IMAGE: "image"
                    }
                }
            }
        }
    },
    // Page object types:
    TYPE: {
        SECTION: "SECTION",
        SECTION_ITEM: {
            TITLE: "TITLE",
            BUTTON: "BUTTON",
            QUESTION: "QUESTION",
            LIKERT_GROUP: "LIKERT_GROUP",
            LIKERT_QUESTION: "LIKERT_QUESTION",
            PAGE_NUM: "PAGE_NUM",
            ICON: "ICON"
        },
    }
};

export const SECTION = {
    JUSTIFY: {
        FLEX_START: "flex-start",
        CENTER: "center",
        FLEX_END: "flex-end",
        SPACE_BETWEEN: "space-between",
        SPACE_AROUND: "space-around",
        SPACE_EVENLY: "space-evenly"
    },
    ALIGN_ITEMS: {
        FLEX_START: "flex-start",
        CENTER: "center",
        FLEX_END: "flex-end",
        STRETCH: "stretch",
        BASELINE: "baseline"
    },
    CLASS_TYPE: {
        NONE: "CLASS_NONE",
        CONTROL: "CLASS_CONTROL", // this.props.classes.controlGrid
        FOOTER: "CLASS_FOOTER", // this.props.classes.footerGrid
    },
    STYLE_TYPE: {
        NONE: "0",
        ONE: "1",
        TWO: "2",
    }
};


// Item Type TITLE definitions:
export const TITLE = {
    FORMAT: {
        CUSTOM: "TITLE_FORMAT_CUSTOM",
        PLAIN: "TITLE_FORMAT_PLAIN",
        PLAIN_CENTER: "TITLE_FORMAT_PLAIN_CENTER",
        PLAIN_RIGHT: "TITLE_FORMAT_PLAIN_RIGHT",
        APP_HEADER: "APP_HEADER",
        PAGE_HEADER: "TITLE_FORMAT_PAGE_HEADER",
        PAGE_FOOTER1: "TITLE_FORMAT_PAGE_FOOTER",
        PAGE_FOOTER2: "TITLE_FORMAT_PAGE_FOOTER2",
        CATEGORY_TITLE_BAR: "CATEGORY_TITLE_BAR",
        // HEADER: "TITLE_FORMAT_HEADER",
        // FOOTER: "TITLE_FORMAT_FOOTER",
        SPACER: "TITLE_FORMAT_SPACER",
        METADATA: "TITLE_FORMAT_METADATA", // ZZZZZ QQQQQ YYYYY
    },
    SOURCE: {
        NONE: "NONE",
        QNR_NAME: "QNR_NAME",
        QNR_DESC: "QNR_DESC",
        QNR_START_PAGE_SCRIPT: "QNR_START_PAGE_SCRIPT",
        QNR_START_PAGE_TEXT: "QNR_START_PAGE_TEXT",
        QNR_CLOSE_PAGE_SCRIPT: "QNR_CLOSE_PAGE_SCRIPT",
        QNR_CLOSE_PAGE_TEXT: "QNR_CLOSE_PAGE_TEXT",
        QNR_METADATA_PAGE_HEADER: "QNR_METADATA_PAGE_HEADER", // Use metadata display-def-page-header...
        QNR_METADATA_PAGE_FOOTER1: "QNR_METADATA_PAGE_FOOTER1", // Use metadata display-def-page-footer...
        QNR_METADATA_PAGE_FOOTER2: "QNR_METADATA_PAGE_FOOTER2", // Use metadata display-def-page-footer...
        CAT_METADATA_TITLE_BAR: "CAT_METADATA_TITLE_BAR", // Use metadata display-def-header-title...
        CAT_NAME: "CAT_NAME",
        CAT_SCRIPT: "CAT_SCRIPT",
        CAT_DESC: "CAT_DESC",
        // CAT_METADATA_FOOTER: "CAT_METADATA_FOOTER", // Use metadata display-def-footer-title...
        GRP_NAME: "GRP_NAME",
        GRP_DESC: "GRP_DESC",
        // GRP_METADATA_HEADER: "GRP_METADATA_HEADER", // Use metadata display-def-header-title...
        // GRP_METADATA_FOOTER: "GRP_METADATA_FOOTER", // Use metadata display-def-footer-title...
        QUE_NAME: "QUE_NAME",
        QUE_DESC: "QUE_DESC",
        // QUE_METADATA_HEADER: "QUE_METADATA_HEADER", // Use metadata display-def-header-title...
        // QUE_METADATA_FOOTER: "QUE_METADATA_FOOTER", // Use metadata display-def-footer-title...
        DEFAULT: "D"
    },
    ID: {
        NONE: null
    },
    NAME: {
        USE_SOURCE: "",
        DEFAULT1: "1",
        DEFAULT2: "2",
        DEFAULT3: "3",
        DEFAULT4: "4",
        REPLACE_DEFAULT4: "x4"
    }
};

export const BUTTON = {
    MODE: {
        START: "START", // YES
        CANCEL: "CANCEL",
        FINISH: "FINISH", // SUBMIT
        NEXT: "NEXT",
        PREVIOUS: "PREVIOUS",
        VIEW_PDF: "VIEW PDF",
        CLOSE: "CLOSE"
    },
    VARIANT: {
        CONTAINED: "contained",
        OUTLINED: "outlined",
        TEXT: "text"
    },
    COLOR: {
        NONE: "", // ""
        DEFAULT: "default",
        INHERIT: "inherit",
        PRIMARY: "primary",
        SECONDARY: "secondary",
    },
    NAME: {
        DEFAULT: ""
    },
    SIZE: {
        SMALL: "small",
        MEDIUM: "medium",
        LARGE: "large"
    }
};

export const ICON_TYPE = {
    GREEN_CHECK_ICON: "green-check-icon"
};


// ---------------------------------------------------------------------------------------------------------------------
// Function
// ---------------------------------------------------------------------------------------------------------------------

// Create Display Definition Object "ddo"
export function createDisplayDefObj() {

    return {
        [OBJ.KEY.DDO.PAGE_ARRAY]: []
    };
}


export function createPage() {

    return {[OBJ.KEY.DDO.PAGE_SECTION_ARRAY]: []};
}


export function addPageToDisplayDefObj(displayDefObj, pageObj) {

    displayDefObj[OBJ.KEY.DDO.PAGE_ARRAY].push(pageObj);
}


export function createPageSection(justify, alignItems, styleType, classType) {

    let obj = {[OBJ.ITEM_ARRAY]: []};  // Sections are divided into items

    obj[OBJ.KEY.PAGE.SECTION.TYPE] = OBJ.TYPE.SECTION;
    obj[OBJ.KEY.PAGE.SECTION.JUSTIFY] = justify;
    obj[OBJ.KEY.PAGE.SECTION.ALIGN_ITEMS] = alignItems;
    obj[OBJ.KEY.PAGE.SECTION.STYLE_TYPE] = styleType;
    obj[OBJ.KEY.PAGE.SECTION.CLASS_TYPE] = classType;

    return obj;
}


export function addSectionToPage(pageObj, sectionObj) {

    pageObj[OBJ.KEY.DDO.PAGE_SECTION_ARRAY].push(sectionObj);
}


// ---------------------------------------------------------------------------------------------------------------------
// ADD OBJECT TO PAGE
// ---------------------------------------------------------------------------------------------------------------------

export function addTitleToSection(sectionObj, format, source, id, name, variant, showNumber = "", showGroupKey = "", showGroupObjArray = [], hasDescription = false) {

    let obj = {};

    obj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.TYPE] =  OBJ.TYPE.SECTION_ITEM.TITLE;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.FORMAT] =  format;  // Plain, Custom
    obj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.SOURCE] =  source;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.ID] =  id;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.NAME] =  name; // Actual Text or (USE_SOURCE) for loacation of text ie.. TITLE.NAME.USE_SOURCE
    obj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.VARIANT] =  variant; // Can be simple variant like "h1" or a custom text obj
    obj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.SHOW_NUMBER] =  showNumber;  // IF not blank then the number to display before name
    obj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.SHOW_GROUP_KEY] =  showGroupKey;  // Used for show group
    obj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.SHOW_GROUP_OBJ_ARRAY] =  showGroupObjArray;  // Used for show group
    obj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.OBJ_HAS_DESCRIPTION] =  hasDescription;  // Used for show group

    sectionObj[OBJ.ITEM_ARRAY].push(obj);
}


export function addIconToSection(sectionObj, image) {

    let obj = {};

    obj[OBJ.KEY.PAGE.SECTION.ITEM.ICON.TYPE] =  OBJ.TYPE.SECTION_ITEM.ICON;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.ICON.IMAGE] =  image;

    sectionObj[OBJ.ITEM_ARRAY].push(obj);
}


export function addButtonToSection(sectionObj, mode, variant, color, name) {

    let obj = {};

    obj[OBJ.KEY.PAGE.SECTION.ITEM.BUTTON.TYPE] =  OBJ.TYPE.SECTION_ITEM.BUTTON;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.BUTTON.MODE] =  mode;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.BUTTON.VARIANT] =  variant;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.BUTTON.COLOR] =  color;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.BUTTON.NAME] =  name;

    sectionObj[OBJ.ITEM_ARRAY].push(obj);
}


export function addQuestionToSection(sectionObj, iObj, showGroupKey = "", showGroupObjArray = [], qNumObj) {

    let obj = {};
    let metadataValue = "";


    // This code needed to accommodate DDLB question types that can have the question name hidden since it is in the DDLB
    if ((qNumObj.useQuestionNumbers)&&(tag.metadataExists(iObj, portalShared.CUSTOM_DDO_NAME))) {
        metadataValue = tag.metadataValue(iObj, portalShared.CUSTOM_DDO_NAME);
        if (metadataValue === "hide") {
            qNumObj.currQuestionNumber += 1;
        }
    }

    obj[OBJ.KEY.PAGE.SECTION.ITEM.QUESTION.TYPE] =  OBJ.TYPE.SECTION_ITEM.QUESTION;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.QUESTION.ID] =  iObj.id;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.QUESTION.SHOW_GROUP_KEY] =  showGroupKey;  // Used for show group
    obj[OBJ.KEY.PAGE.SECTION.ITEM.QUESTION.SHOW_GROUP_OBJ_ARRAY] =  showGroupObjArray;  // Used for show group
    obj[OBJ.KEY.PAGE.SECTION.ITEM.QUESTION.SHOW_NUMBER] =  qNumObj.currQuestionNumber + qNumObj.questionNumberAppend;  // Used for question numbers

    sectionObj[OBJ.ITEM_ARRAY].push(obj);
}


export function createLikertGroupObj(id) {

    let obj = {};

    obj[OBJ.KEY.PAGE.SECTION.ITEM.LIKERT_GROUP.TYPE] =  OBJ.TYPE.SECTION_ITEM.LIKERT_GROUP;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.LIKERT_GROUP.ID] =  id;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.LIKERT_GROUP.QUESTIONS] =  [];

    return obj;
}


export function addLikertQuestionToLikertGroup(likertGroupObj, id, qNumObj, showGroupKey = "", showGroupObjArray = []) {

    let obj = {};

    obj[OBJ.KEY.PAGE.SECTION.ITEM.LIKERT_QUESTION.TYPE] =  OBJ.TYPE.SECTION_ITEM.LIKERT_QUESTION;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.LIKERT_QUESTION.ID] =  id;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.LIKERT_QUESTION.SHOW_GROUP_KEY] =  showGroupKey;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.LIKERT_QUESTION.SHOW_GROUP_OBJ_ARRAY] =  showGroupObjArray;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.LIKERT_QUESTION.SHOW_NUMBER] =  questionSubNumber(qNumObj, qNumObj.subnumberLikertQuestions);

    likertGroupObj[OBJ.KEY.PAGE.SECTION.ITEM.LIKERT_GROUP.QUESTIONS].push(obj);
}


export function addLikertGroupToSection(sectionObj, likertGroupObj) {

    sectionObj[OBJ.ITEM_ARRAY].push(likertGroupObj);
}


export function getLikertGroupQuestions(likertGroupObj) {

    return likertGroupObj[OBJ.KEY.PAGE.SECTION.ITEM.LIKERT_GROUP.QUESTIONS];
}


export function getSectionItemType(obj) {

    return obj[BASE.TYPE];
}


export function getObjValue(obj, key) {

    return obj[key];
}


export function getObjValueWithConversion(obj, key) {

    let value = "";

    switch (obj.t) {
        case OBJ.TYPE.SECTION:
            value = convertSectionValueToText(key, obj[key]);
            break;
        case OBJ.TYPE.SECTION_ITEM.TITLE:
            value = "";
            break;
        case OBJ.TYPE.SECTION_ITEM.BUTTON:
            value = "";
            break;
        default:
            value = obj[key];
            break;
    }

    return value;
}


export function convertSectionValueToText(key, storedValue) {

    let convertedValue = "";

    switch(key) {
        case OBJ.KEY.PAGE.SECTION.JUSTIFY:
            switch (storedValue) {
                case SECTION.JUSTIFY.FLEX_START:
                    convertedValue = "flex-start";
                    break;
                case SECTION.JUSTIFY.CENTER:
                    convertedValue = "center";
                    break;
                case SECTION.JUSTIFY.FLEX_END:
                    convertedValue = "flex-end";
                    break;
                case SECTION.JUSTIFY.SPACE_BETWEEN:
                    convertedValue = "space-between";
                    break;
                case SECTION.JUSTIFY.SPACE_AROUND:
                    convertedValue = "space-around";
                    break;
                case SECTION.JUSTIFY.SPACE_EVENLY:
                    convertedValue = "space-evenly";
                    break;
                default:
                    convertedValue = storedValue;
                    break;
            }
            break;
        case OBJ.KEY.PAGE.SECTION.ALIGN_ITEMS:
            switch (storedValue) {
                case SECTION.ALIGN_ITEMS.FLEX_START:
                    convertedValue = "flex-start";
                    break;
                case SECTION.ALIGN_ITEMS.CENTER:
                    convertedValue = "center";
                    break;
                case SECTION.ALIGN_ITEMS.FLEX_END:
                    convertedValue = "flex-end";
                    break;
                case SECTION.ALIGN_ITEMS.STRETCH:
                    convertedValue = "stretch";
                    break;
                case SECTION.ALIGN_ITEMS.BASELINE:
                    convertedValue = "baseline";
                    break;
                default:
                    convertedValue = storedValue;
                    break;
            }
            break;
        case OBJ.KEY.PAGE.SECTION.STYLE_TYPE:
            convertedValue = storedValue;
            break;
        case OBJ.KEY.PAGE.SECTION.CLASS_TYPE:
            convertedValue = storedValue;
            break;
        default:
            convertedValue = storedValue;
            break;
    }

    return convertedValue;
}


// Convert a source to the actual text from the instance
export function convertTitleSourceReturnTitlesObj(questionnaire, itemObj, currPageNum, numberOfPages, qualifierAppendedToId) {
// QQQQQ
    let qnr = questionnaire;

    let source = getObjValue(itemObj, OBJ.KEY.PAGE.SECTION.ITEM.TITLE.SOURCE);
    
    let titlesObj = {
        source: source,
        currentTemplate: null,
        isDescription: false,
        isScript: false,
        text: "",
        textBase: "",
        textArray: null,
        textObj: null,
        //textObj2: null,
        itemObj: itemObj,
        currPageNum: currPageNum,
        numberOfPages: numberOfPages,
        qNum: "",
        categoryId: "",
        category: null,
        groupId: "",
        group: null,
        questionId: "",
        question: null,
        objKey: "",
        isLeftAligned: true,
        alignQuestionsWithAnswers: false,
        CustomStyle: null // NOT used yet, but if source = TITLE.SOURCE.QNR_NAME and alignQuestionsWithAnswers could be used by singleSelect
    };

    switch(source) {
        case TITLE.SOURCE.NONE:
            titlesObj.text = itemObj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.NAME];  // If no source must be a freeform entry so use it
            titlesObj.objKey = "SourceNone" + qualifierAppendedToId;
            break;
        case TITLE.SOURCE.QNR_NAME:
            titlesObj.text = qnr.name;
            titlesObj.objKey = "QnrName-" + qnr.id + qualifierAppendedToId;
            titlesObj.currentTemplate = qnr;
            break;
        case TITLE.SOURCE.QNR_DESC:
            titlesObj.text = qnr.description;
            titlesObj.objKey = "QnrDesc-" + qnr.id + qualifierAppendedToId;
            titlesObj.currentTemplate = qnr;
            titlesObj.isDescription = true;
            break;
        case TITLE.SOURCE.QNR_START_PAGE_SCRIPT:
            titlesObj.text = tag.metadataValue(qnr, portalShared.QNR_START_PAGE_SCRIPT, "", "");
            titlesObj.objKey = "QnrStartPageScript-" + qnr.id + qualifierAppendedToId;
            titlesObj.currentTemplate = qnr;
            break;
        case TITLE.SOURCE.QNR_START_PAGE_TEXT:
            titlesObj.text = tag.metadataValueObj(qnr, portalShared.CUSTOM_QNR_START_PAGE_OBJ).defaultText;
            titlesObj.objKey = "QnrStartPageText-" + qnr.id + qualifierAppendedToId;
            titlesObj.currentTemplate = qnr;
            break;
        case TITLE.SOURCE.QNR_CLOSE_PAGE_SCRIPT:
            titlesObj.text = tag.metadataValue(qnr, portalShared.QNR_CLOSE_PAGE_SCRIPT, "", "");
            titlesObj.objKey = "QnrClosePageScript-" + qnr.id + qualifierAppendedToId;
            titlesObj.currentTemplate = qnr;
            break;
        case TITLE.SOURCE.QNR_CLOSE_PAGE_TEXT:
            titlesObj.text = tag.metadataValueObj(qnr, portalShared.CUSTOM_QNR_CLOSE_PAGE_OBJ).defaultText;
            titlesObj.objKey = "QnrClosePageText-" + qnr.id + qualifierAppendedToId;
            titlesObj.currentTemplate = qnr;
            break;
        case TITLE.SOURCE.QNR_METADATA_PAGE_HEADER:
            titlesObj.textObj = getQnrAppBarOrHeaderOrFooterObj(qnr, itemObj,"pageHeader", currPageNum, numberOfPages);
            titlesObj.objKey = "QnrPageHeader-" + qnr.id + qualifierAppendedToId;
            titlesObj.currentTemplate = qnr;
            break;
        case TITLE.SOURCE.QNR_METADATA_PAGE_FOOTER1:
            titlesObj.textObj = getQnrAppBarOrHeaderOrFooterObj(qnr, itemObj,"pageFooter1", currPageNum, numberOfPages);
            titlesObj.objKey = "QnrPageFooter1-" + qnr.id + qualifierAppendedToId;
            titlesObj.currentTemplate = qnr;
            break;
        case TITLE.SOURCE.QNR_METADATA_PAGE_FOOTER2:
            titlesObj.textObj = getQnrAppBarOrHeaderOrFooterObj(qnr, itemObj,"pageFooter2", currPageNum, numberOfPages);
            titlesObj.objKey = "QnrPageFooter2-" + qnr.id + qualifierAppendedToId;
            titlesObj.currentTemplate = qnr;
            break;
        case TITLE.SOURCE.CAT_NAME:
            titlesObj.categoryId = itemObj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.ID]
            titlesObj.category = getCategory(qnr, titlesObj.categoryId);
            titlesObj.text = titlesObj.category.name;
            titlesObj.objKey = "CatName-" + titlesObj.categoryId + qualifierAppendedToId;
            titlesObj.currentTemplate = titlesObj.category;
            break;
        // case TITLE.SOURCE.CAT_SCRIPT:
        //     titlesObj.categoryId = itemObj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.ID]
        //     titlesObj.category = getCategory(qnr, titlesObj.categoryId);
        //     titlesObj.text = tag.metadataValue(titlesObj.category, portalShared.CATEGORY_SCRIPT, "","", true);
        //     titlesObj.objKey = "CatScript-" + titlesObj.categoryId + qualifierAppendedToId;
        //     titlesObj.currentTemplate = titlesObj.category;
        //     titlesObj.isScript = true;
        //     break;
        case TITLE.SOURCE.CAT_DESC:
            titlesObj.categoryId = itemObj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.ID]
            titlesObj.category = getCategory(qnr, titlesObj.categoryId);
            titlesObj.text = titlesObj.category.description;
            titlesObj.isDescription = true;
            titlesObj.objKey = "CatDesc-" + titlesObj.categoryId + qualifierAppendedToId;
            titlesObj.currentTemplate = titlesObj.category;
            break;

        case TITLE.SOURCE.CAT_METADATA_TITLE_BAR:
            titlesObj.categoryId = itemObj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.ID]
            titlesObj.category = getCategory(qnr, titlesObj.categoryId);
            titlesObj.textObj = getCategoryTitleBarObj(qnr, titlesObj.category, currPageNum, numberOfPages);
            titlesObj.objKey = "CatTitleBar-" + titlesObj.categoryId + qualifierAppendedToId;
            titlesObj.currentTemplate = titlesObj.category;
            break;
        case TITLE.SOURCE.GRP_NAME:
            titlesObj.groupId = itemObj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.ID]
            titlesObj.group = getGroup(qnr, titlesObj.groupId);
            titlesObj.qNum = itemObj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.SHOW_NUMBER];
            titlesObj.textBase = titlesObj.qNum === "" ? "" : titlesObj.group.name;
            titlesObj.text = titlesObj.qNum === "" ? titlesObj.group.name : (titlesObj.qNum + " " + titlesObj.group.name);
            titlesObj.objKey = "GrpName-" + titlesObj.groupId + qualifierAppendedToId;
            titlesObj.currentTemplate = titlesObj.group;
            break;
        case TITLE.SOURCE.GRP_DESC:
            titlesObj.groupId = itemObj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.ID]
            titlesObj.group = getGroup(qnr, titlesObj.groupId);
            titlesObj.text = titlesObj.group.description;
            titlesObj.isDescription = true;
            titlesObj.objKey = "GrpDesc-" + titlesObj.groupId + qualifierAppendedToId;
            titlesObj.currentTemplate = titlesObj.group;
            break;
        case TITLE.SOURCE.QUE_NAME:
            titlesObj.questionId = itemObj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.ID]
            titlesObj.question = getQuestion(qnr, titlesObj.questionId);
            titlesObj.qNum = itemObj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.SHOW_NUMBER];
            titlesObj.textBase = titlesObj.qNum === "" ? "" : titlesObj.question.name;
            titlesObj.text = titlesObj.qNum === "" ? titlesObj.question.name : (titlesObj.qNum + " " + titlesObj.question.name);
            titlesObj.objKey = "QueName-" + titlesObj.questionId + qualifierAppendedToId;
            titlesObj.currentTemplate = titlesObj.question;
            break;
        case TITLE.SOURCE.QUE_DESC:
            titlesObj.questionId = itemObj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.ID]
            titlesObj.question = getQuestion(qnr, titlesObj.questionId);
            titlesObj.text = titlesObj.question.description;
            titlesObj.isDescription = true;
            titlesObj.objKey = "QueDesc-" + titlesObj.questionId + qualifierAppendedToId;
            titlesObj.currentTemplate = titlesObj.question;
            break;
        // case TITLE.SOURCE.DEFAULT:
        //     titlesObj.text = convertTitleNameDefault(qnr, itemObj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.NAME]);
        //     titlesObj.objKey = "SourceDefaultText" + qualifierAppendedToId;
        //     titlesObj.currentTemplate = titlesObj.question;
        //     break;
        default:
            titlesObj.text = "";
            break;
    }

    // METADATA used to allow titles to have text|variant I don't think this will be needed anymore ZZZZZ
    // if (itemObj.format !== TITLE.FORMAT.METADATA) {
    //     if (typeof titlesObj.text === "string") {
    //         if (titlesObj.text.indexOf("|")) {
    //             let tempArray = titlesObj.text.split("|");
    //             titlesObj.textArray = tempArray;
    //             titlesObj.text = tempArray[0];
    //         }
    //     }
    // }

    // titlesObj.isLeftAligned = !(tag.metadataExists(qnr, portalShared.DISPLAY_DEF_ALIGN_QUESTION_ANSWER_RIGHT) || tag.metadataExists((titlesObj.group !== null) ? titlesObj.group : titlesObj.question, portalShared.DISPLAY_DEF_ALIGN_QUESTION_ANSWER_RIGHT));
    // titlesObj.alignQuestionsWithAnswers = tag.metadataExists((titlesObj.group !== null) ? titlesObj.group : titlesObj.question, portalShared.DISPLAY_DEF_ALIGN_ANSWERS_WITH_QUESTION);

    return titlesObj;
}

const metadataImageTags = {
    appHeader: [portalShared.HEADER_APPBAR_IMAGE_LEFT, portalShared.HEADER_APPBAR_IMAGE_LEFT, portalShared.HEADER_APPBAR_IMAGE_LEFT],
    pageHeader: [portalShared.PAGE_HEADER_IMAGE_LEFT, portalShared.PAGE_HEADER_IMAGE_CENTER, portalShared.PAGE_HEADER_IMAGE_RIGHT],
    pageFooter1: [portalShared.PAGE_FOOTER1_IMAGE_LEFT, portalShared.PAGE_FOOTER1_IMAGE_CENTER, portalShared.PAGE_FOOTER1_IMAGE_RIGHT],
    pageFooter2: [portalShared.PAGE_FOOTER2_IMAGE_LEFT, portalShared.PAGE_FOOTER2_IMAGE_CENTER, portalShared.PAGE_FOOTER2_IMAGE_RIGHT],
}


// Questionnaires can have repeating headers on each page    getPageHeaderObj
export function getQnrAppBarOrHeaderOrFooterObj(qnr, iObj, objType, currPageNum, numberOfPages) {
// QQQQQ // CUSTOM_QNR_MAIN_PAGES_OBJ QQQQQ

    let positionArray = ["Left", "Center", "Right"];

    let obj =  {
        titleLeft: "",
        titleLeftVariant: "",
        titleLeftIsImage: false,
        titleCenter: "",
        titleCenterVariant: "",
        titleCenterIsImage: false,
        titleRight: "",
        titleRightVariant: "",
        titleRightIsImage: false
    }

    // NOTE: There cannot be header images without a custom main pages
    if (tag.metadataExists(qnr, portalShared.CUSTOM_QNR_MAIN_PAGES_OBJ)) {

        let mainPagesObj = tag.metadataValueObj(qnr, portalShared.CUSTOM_QNR_MAIN_PAGES_OBJ);

        for (let i = 0; i < positionArray.length; i++) {

            switch (mainPagesObj[objType + positionArray[i] + "Type"]) {
                case "none":
                    break;
                case "text":
                    obj["title" + positionArray[i]] = getTitleTextFromMetadataObj(qnr, mainPagesObj[objType + positionArray[i] + "TextObj"], qnr, currPageNum, numberOfPages);
                    obj["title" + positionArray[i] + "Variant"] = mainPagesObj[objType + positionArray[i] + "TextObj"]["variant"];
                    break;
                case "image":
                    if (tag.metadataExists(qnr, metadataImageTags[objType][i])) {
                        obj["title" + positionArray[i] + "IsImage"] = true;
                        obj["title" + positionArray[i]] = tag.metadataValue(qnr, metadataImageTags[objType][i]); // Image is saved into text field because it is Base64 encoded image
                    }
                    break;
                // no default
            }
        }
    }

    return  obj;
}


export function getCategoryTitleBarObj(qnr, catObj, currPageNum, numberOfPages) {

    // First check for standard metadata tags
    // let useTitleBar = tag.metadataValue(catObj, portalShared.CATEGORY_TITLE_BAR);
    // let titleLeft = getTitleBarText(qnr, catObj, portalShared.CATEGORY_TITLE_BAR_LEFT, currPageNum, numberOfPages);
    // let titleCenter = getTitleBarText(qnr, catObj, portalShared.CATEGORY_TITLE_BAR_CENTER, currPageNum, numberOfPages);
    // let titleRight = getTitleBarText(qnr, catObj, portalShared.CATEGORY_TITLE_BAR_RIGHT, currPageNum, numberOfPages);
    //
    // useTitleBar = (titleLeft || titleCenter || titleRight);

    let titleObj = {
        useTitleBar: false, // useTitleBar,
        categoryCustomObj: null,
        useCustomLeft: false,
        useCustomCenter: false,
        useCustomRight: false,
        titleLeft: "", // titleLeft.text,
        //titleLeftVariant: titleLeft.variant,
        //titleLeftIsImage: false,
        titleLeftCustomObj: null,
        titleCenter: "", // titleCenter.text,
        //titleCenterVariant: titleCenter.variant,
        //titleCenterIsImage: false,
        titleCenterCustomObj: null,
        titleRight: "", // titleRight.text,
        //titleRightVariant: titleRight.variant,
        //titleRightIsImage: false,
        titleRightCustomObj: null,
    };

    // NOTE: if both metatdata and custom metadata defined titles the custom text is used.
    if (tag.metadataExists(catObj, portalShared.CUSTOM_DDO_CAT_FORMAT)) {
        titleObj.categoryCustomObj = JSON.parse(tag.metadataValue(catObj, portalShared.CUSTOM_DDO_CAT_FORMAT));
        if (titleObj.categoryCustomObj.titlebar) {

            titleObj.useTitleBar = true;

            titleObj.titleLeftCustomObj = titleObj.categoryCustomObj.leftTitleCustomTextObj;
            titleObj.useCustomLeft = (titleObj.categoryCustomObj.leftTitleCustomTextObj.title !== ""); // If no title then don't use the other custom properties

            titleObj.titleCenterCustomObj = titleObj.categoryCustomObj.centerTitleCustomTextObj;
            titleObj.useCustomCenter = (titleObj.categoryCustomObj.centerTitleCustomTextObj.title !== ""); // If no title then don't use the other custom properties

            titleObj.titleRightCustomObj = titleObj.categoryCustomObj.rightTitleCustomTextObj;
            titleObj.useCustomRight = (titleObj.categoryCustomObj.rightTitleCustomTextObj.title !== ""); // If no title then don't use the other custom properties
        }
    }

    return titleObj;
}





// export function convertTitleNameDefault(qnr = {}, titleNameDefault) {
//
//     let name = "";
//
//     switch(titleNameDefault) {
//         case TITLE.NAME.DEFAULT1:
//             name = "Begin Questionnaire?";
//             break;
//         case TITLE.NAME.DEFAULT2:
//             name = "Start Questionnaire?";
//             break;
//         case TITLE.NAME.DEFAULT3:
//             name = "Begin Survey?";
//             break;
//         case TITLE.NAME.DEFAULT4:
//             name = "Thank you for providing your feedback to us, we greatly appreciate it. Your answers have been recorded, please close your browser window.";
//             break;
//         case TITLE.NAME.REPLACE_DEFAULT4:
//             name = tag.metadataValue(qnr, portalShared.CLOSE_PAGE_REPLACE_DEFAULT_TEXT, "", "")
//             break;
//         default:
//             name = "Begin Questionnaire?";
//             break;
//     }
//
//     return name;
// }


// export function scriptBeforeExists(questionnaire) {
//
//     let scriptBefore = tag.metadataValue(questionnaire, "user-script");
//
//     return (scriptBefore !== "")
// }


// export function scriptAfterExists(questionnaire) {
//
//     let scriptBefore = tag.metadataValue(questionnaire, "user-script"); // qi.metadata.metadataItems["user-script-bottom"] !== undefined ? qi.metadata.metadataItems["user-script-bottom"] : "";
//
//     return (scriptBefore !== "")
// }


export function convertButtonNameDefault(buttonName, buttonMode) {

    let name = buttonName;

    if (buttonName === BUTTON.NAME.DEFAULT) {

        // Use application default for the button name

        switch(buttonMode) {
            case BUTTON.MODE.START:
                name = "YES";
                break;
            case BUTTON.MODE.CANCEL:
                name = "CANCEL";
                break;
            case BUTTON.MODE.FINISH:
                name = "SUBMIT";
                break;
            case BUTTON.MODE.NEXT:
                name = "NEXT";
                break;
            case BUTTON.MODE.PREVIOUS:
                name = "PREVIOUS";
                break;
            case BUTTON.MODE.CLOSE:
                name = "CLOSE";
                break;
            case BUTTON.MODE.VIEW_PDF:
                name = "VIEW PDF";
                break;
            default:
                name = "";
                break;
        }
    }

    return name;
}


export function convertButtonColor(buttonColor) {

    let color = "";

    switch(buttonColor) {
        case BUTTON.COLOR.NONE:
            color = "";
            break;
        case BUTTON.COLOR.DEFAULT:
            color = "default";
            break;
        case BUTTON.COLOR.INHERIT:
            color = "inherit";
            break;
        case BUTTON.COLOR.PRIMARY:
            color = "primary";
            break;
        case BUTTON.COLOR.SECONDARY:
            color = "secondary";
            break;
        default:
            color = "default";
            break;
    }

    return color;
}


export function getTitleTextFromMetadataObj(qnr, metadataTextObj, iObj, currPageNum, numberOfPages) {

    let tempText = "";
    let pageNum = "";
    let pageOf = "";

    if (metadataTextObj.title !== "") {

        tempText = metadataTextObj.title;

        if ((tempText.toUpperCase() === portalShared.METADATA_VALUE_PREFIX_PAGENUM.toUpperCase())) {

            let customCategoryObj = null;
            let categoryPortalPageBreak = false;
            if (tag.metadataExistsObj(iObj, portalShared.CUSTOM_DDO_CAT_FORMAT)) {
                customCategoryObj = tag.metadataValueObj(iObj, portalShared.CUSTOM_DDO_CAT_FORMAT);
                categoryPortalPageBreak = customCategoryObj.portalPageBreak;
            }

            if (categoryPortalPageBreak || currPageNum === 1) {
                pageNum = (categoryPortalPageBreak !== "") ? categoryPortalPageBreak : currPageNum;
            }

            if (tag.metadataExists(qnr, portalShared.PAGE_OF)) {
                pageOf = (tag.metadataValue(qnr, portalShared.PAGE_OF) !== "") ? tag.metadataValue(qnr, portalShared.PAGE_OF) : numberOfPages;
            }

            if (pageNum !== "") {
                if (pageOf !== "") {
                    tempText = "Page " + pageNum + " of " + pageOf;
                }
                else {
                    tempText = "Page " + pageNum;
                }
            }
        }
    }

    return tempText;
}


export function getTitleBarText(qnr, iObj, mdPropText, currPageNum, numberOfPages) {

    let tempObj = {text: "", isImage: false, variant: ""};
    let pageNum = "";
    let pageOf = "";

    if (tag.metadataExists(iObj, mdPropText)) {

        tempObj.text = tag.metadataValue(iObj, mdPropText);

        if (typeof tempObj.text === "object") {
            tempObj.text = tempObj.text.title;
            tempObj.variant = tempObj.text;
            return tempObj; // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! quick fix for headers and footers
        }

        // This was used to pass text with a variant ie.. My Text|h4
        if (tempObj.text !== undefined) {
            if (tempObj.text.includes("|")) {
                let parsedArray = tempObj.text.split("|");
                tempObj.text = parsedArray[0];
                tempObj.variant = parsedArray[1];
            }
        }

        if ((tempObj.text !== undefined) && (tempObj.text.toUpperCase() === portalShared.METADATA_VALUE_PREFIX_PAGENUM.toUpperCase())) {

            let customCategoryObj = null;
            let categoryPortalPageBreak = false;
            if (tag.metadataExistsObj(iObj, portalShared.CUSTOM_DDO_CAT_FORMAT)) {
                customCategoryObj = tag.metadataValueObj(iObj, portalShared.CUSTOM_DDO_CAT_FORMAT);
                categoryPortalPageBreak = customCategoryObj.portalPageBreak;
            }

            if (categoryPortalPageBreak || currPageNum === 1) {
                pageNum = (categoryPortalPageBreak !== "") ? categoryPortalPageBreak : currPageNum;
            }

            if (tag.metadataExists(qnr, portalShared.PAGE_OF)) {
                pageOf = (tag.metadataValue(qnr, portalShared.PAGE_OF) !== "") ? tag.metadataValue(qnr, portalShared.PAGE_OF) : numberOfPages;
            }

            if (pageNum !== "") {
                if (pageOf !== "") {
                    tempObj.text = "Page " + pageNum + " of " + pageOf;
                }
                else {
                    tempObj.text = "Page " + pageNum;
                }
            }
        }
        else if (mdPropText.includes === "image-") {
            // CUSTOM_QNR_MAIN_PAGES_OBJ QQQQQ
            tempObj.isImage = true;
            tempObj.text = tag.metadataValue(iObj, mdPropText);
        }
        else if (mdPropText === portalShared.CUSTOM_QNR_MAIN_PAGES_OBJ) {
            tempObj.isImage = false;
            tempObj.text = "";
        }
        // else if (mdPropText === portalShared.PAGE_FOOTER1_RIGHT) {
        //     tempObj.isImage = false;
        //     tempObj.text = tag.metadataValue(iObj, portalShared.PAGE_FOOTER1_RIGHT);
        // }

        // else if (mdPropText === portalShared.PAGE_FOOTER2_RIGHT) {
        //     tempObj.isImage = false;
        //     tempObj.text = tag.metadataValue(iObj, portalShared.PAGE_FOOTER2_RIGHT);
        // }
        else if (tempObj.text.substring(0, portalShared.METADATA_VALUE_PREFIX_SRC.length).toUpperCase() === portalShared.METADATA_VALUE_PREFIX_SRC.toUpperCase()) {
            tempObj.isImage = true;
            tempObj.text = tempObj.text.substring(0, portalShared.METADATA_VALUE_PREFIX_SRC.length)
        }
    }

    return tempObj;
}


export function getCategory(questionnaire, categoryId) {

    let qnr = questionnaire;
    let isInstance = (qnr.questionCategories !== undefined);
    let categoryObj = {};

    let categories = isInstance ? qnr.questionCategories : qnr.questionCategoryTemplates;
    for (let i = 0; i < categories.length; i++) {
        if (categories[i].id === categoryId) {
            categoryObj = categories[i];
        }
    }

    return categoryObj;
}


export function getGroup(questionnaire, groupId) {

    let qnr = questionnaire;
    let isInstance = (qnr.questionGroups !== undefined);
    let groupObj = {};

    let categories = null;
    let groups = null;

    if (isInstance) {
        groups = qnr.questionGroups;

        for (let i = 0; i < groups.length; i++) {
            if (groups[i].id === groupId) {
                groupObj = groups[i];
                break;
            }
        }
    }
    else {
        categories = qnr.questionCategoryTemplates;

        for (let c = 0; c < categories.length; c++) {

            groups = categories[c].groupTemplates;

            for (let i = 0; i < groups.length; i++) {
                if (groups[i].id === groupId) {
                    groupObj = groups[i];
                    break;
                }
            }
        }
    }

    return groupObj;
}


export function getGroupByKey(questionnaireInstance, groupKey) {

    let qi = questionnaireInstance;
    let groupObj = {};

    for (let i = 0; i < qi.questionGroups.length; i++) {
        if (qi.questionGroups[i].templateKey === groupKey) {
            groupObj = qi.questionGroups[i];
        }
    }

    return groupObj;
}


export function getQuestion(questionnaire, questionId) {

    let qnr = questionnaire;
    let isInstance = (qnr.questions !== undefined);

    let questionObj = {};

    let categories = null;
    let groups = null;
    let questions = null;

    if (isInstance) {
        questions = qnr.questions;

        for (let i = 0; i < questions.length; i++) {
            if (questions[i].id === questionId) {
                questionObj = questions[i];
                break;
            }
        }
    }
    else {

        // let isSimple = (qnr.questionTemplates.length > 0);
        //
        // if (isSimple) {
        //     questions = qnr.questionTemplates;
        //
        //     for (let i = 0; i < questions.length; i++) {
        //         if (questions[i].id === questionId) {
        //             questionObj = questions[i];
        //             break;
        //         }
        //     }
        // }
        // else {

            categories = qnr.questionCategoryTemplates;

            for (let c = 0; c < categories.length; c++) {

                groups = categories[c].groupTemplates;

                for (let g = 0; g < groups.length; g++) {

                    questions = groups[g].questionTemplates !== undefined ? groups[g].questionTemplates : [];

                    for (let i = 0; i < questions.length; i++) {
                        if (questions[i].id === questionId) {
                            questionObj = questions[i];
                            break;
                        }
                    }
                }
            }
        //}

    }

    return questionObj;
}


export function getQuestionNumber(questionnaire, currQuestionNumber) {

    let qnr = questionnaire;

    let currNum = "";

    if (tag.metadataExists(qnr, portalShared.SHOW_QUESTION_NUMBERS)) {

        currNum = currQuestionNumber;
    }

    return currNum;
}


export function getLikertQuestionShowGroupKey(qnr, questionItemObj) {

    let likertShowGroupKey = "";

    let questionId = getObjValue(questionItemObj, OBJ.KEY.PAGE.SECTION.ITEM.QUESTION.ID);
    let question = getQuestion(qnr, questionId);

    // Since a LIKERT group can have multiple question to a single answer set tag.USE_SHOW_GROUP metadata tells portal to ignore any show groups for this question
    if (!tag.metadataExists(question, portalShared.DO_NOT_USE_SHOW_GROUP)) {

        likertShowGroupKey = questionItemObj.showGroupKey;
    }

    return likertShowGroupKey;
}


export function showGroup(questionnaire, showGroupKey) {

    let show = false;

    let isInstance = questionnaire.questions !== undefined;

    // BUTTONS have undefined showGroupKey
    if ((showGroupKey === "")||(showGroupKey === undefined)) {
        show = true; // no showGroupKey then always visible
    }
    else {

        let qnr = questionnaire;
        //let isInstance = questionnaire.questions !== undefined;

        let categories = null;
        let groups = null;
        let questions = null;
        let question = null;

        if (isInstance) {

            questions = qnr.questions;

            for (let i = 0; i < questions.length; i++) {

                if (!tag.metadataExists(questions[i], portalShared.DO_NOT_USE_SHOW_GROUP)) {

                    for (let j = 0; j < questions[i].answerOptions.length; j++) {

                        for (let k = 0; k < questions[i].answerOptions[j].groupKeys.length; k++) {

                            if (questions[i].answerOptions[j].groupKeys[k] === showGroupKey) {

                                // If any answer that triggers the group then make visible
                                if (questions[i].answerOptions[j].selected) {
                                    show = true;
                                    break;
                                }
                            }
                        }
                    }

                }
            }
        }
        else {

            categories = qnr.questionCategoryTemplates;

            for (let c = 0; c < categories.length; c++) {

                groups = categories[c].groupTemplates !== undefined ? categories[c].groupTemplates : [];

                for (let g = 0; g < groups.length; g++) {

                    questions = groups[g].questionTemplates !== undefined ? groups[g].questionTemplates : [];

                    for (let q = 0; q < questions.length; q++) {

                        question = questions[q];

                        if (!tag.metadataExists(question, portalShared.DO_NOT_USE_SHOW_GROUP)) {

                            for (let j = 0; j < question.answerOptionTemplates.length; j++) {

                                for (let k = 0; k < question.answerOptionTemplates[j].importGroupKeys.length; k++) {

                                    if (question.answerOptionTemplates[j].importGroupKeys[k] === showGroupKey) {

                                        // If any answer that triggers the group then make visible
                                        if ((question.answerOptionTemplates[j].selected !== undefined) && question.answerOptionTemplates[j].selected) {
                                            show = true;
                                            break;
                                        }
                                    }
                                }
                            }

                        }

                    }
                }
            }

        }
    }

    return show;
}


export function getAllShowGroupKeyArray(questionnaire) {

    let allShowGroupKeyArray = [];

    let qnr = questionnaire;
    let isInstance = questionnaire.questions !== undefined;

    let categories = null;
    let groups = null;
    let questions = null;
    let answers = null;
    let groupKeys = null;

    if (isInstance) {
        questions = isInstance ? qnr.questions : qnr.questionTemplates;

        for (let q = 0; q < questions.length; q++) {

            answers = questions[q].answerOptions;

            for (let a = 0; a < answers.length; a++){

                let groupKeys = answers[a].groupKeys;

                for (let k = 0; k < groupKeys.length; k++) {

                    let showGroupTemplateKey = groupKeys[k];
                    if (!allShowGroupKeyArray.includes(showGroupTemplateKey)) {

                        allShowGroupKeyArray.push(showGroupTemplateKey);
                    }
                }
            }
        }
    }
    else {
        categories = qnr.questionCategoryTemplates;

        // Issues with debugging so using var instead of let
        for (var c = 0; c < categories.length; c++) {

            groups = categories[c].groupTemplates !== undefined ? categories[c].groupTemplates : [];

            for (var g = 0; g < groups.length; g++) {

                questions = groups[g].questionTemplates !== undefined ? groups[g].questionTemplates : [];

                for (var q = 0; q < questions.length; q++) {

                    answers = questions[q].answerOptionTemplates;

                    if (answers !== undefined) {

                        for (var a = 0; a < answers.length; a++){

                            groupKeys = answers[a].importGroupKeys;

                            if (groupKeys !== undefined) {

                                for (var k = 0; k < groupKeys.length; k++) {

                                    let showGroupTemplateKey = groupKeys[k];
                                    if (!allShowGroupKeyArray.includes(showGroupTemplateKey)) {

                                        allShowGroupKeyArray.push(showGroupTemplateKey);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    return allShowGroupKeyArray;
}

// ZZZZZ QQQQQ YYYYY
export function addSurveyMetadataSection(pageObj, metadata) {

    let sectionObj = createPageSection(SECTION.JUSTIFY.CENTER, SECTION.ALIGN_ITEMS.STRETCH, SECTION.STYLE_TYPE.ONE, SECTION.CLASS_TYPE.CONTROL);

    // METADATA used to allow titles to have text|variant I don't think this will be needed anymore ZZZZZ
    for (var key in metadata) {
        if (metadata.hasOwnProperty(key)) {
            addTitleToSection(sectionObj, TITLE.FORMAT.METADATA, TITLE.SOURCE.NONE, TITLE.ID.NONE, key + "|" + metadata[key], font.VARIANT.H5, "", [], "");
        }
    }

    addTitleToSection(sectionObj, TITLE.FORMAT.SPACER, TITLE.SOURCE.NONE, TITLE.ID.NONE, "", font.VARIANT.H5, "", "","");
    addSectionToPage(pageObj, sectionObj);
}

// ZZZZZ YYYYY QQQQQ
export function addSurveyFilteredMetadataSection(pageObj, metadata) {

    // In READONLY mode the survey can display a header section that shows all the token substitutions.

    let addKey = false;

    let sectionObj = createPageSection(SECTION.JUSTIFY.CENTER, SECTION.ALIGN_ITEMS.STRETCH, SECTION.STYLE_TYPE.ONE, SECTION.CLASS_TYPE.CONTROL);

    for (var key in metadata) {
        if (metadata.hasOwnProperty(key)) {
            addKey = false;
            switch(key) {
                case "Date Surveyed":
                    addKey = ((metadata[key] !== "")||(metadata[key] !== null))
                    break;
                case "TypeOfProduct":
                    addKey = ((metadata[key] !== "")||(metadata[key] !== null))
                    break;
                case "OperatorId":
                    addKey = ((metadata[key] !== "")||(metadata[key] !== null))
                    break;
                case "Branch":
                    addKey = ((metadata[key] !== "")||(metadata[key] !== null))
                    break;
                case "NameOfProduct":
                    addKey = ((metadata[key] !== "")||(metadata[key] !== null))
                    break;
                case "DateOfEvent":
                    addKey = ((metadata[key] !== "")||(metadata[key] !== null))
                    break;
                default:
                    console.log("displayDefObjConstants.js  Switch default E01"); // eslint-disable-line
            }

            if (addKey) {
                // METADATA used to allow titles to have text|variant I don't think this will be needed anymore ZZZZZ
                addTitleToSection(sectionObj, TITLE.FORMAT.METADATA, TITLE.SOURCE.NONE, TITLE.ID.NONE, key + "|" + metadata[key], font.VARIANT.H5, "", "", "");
            }
        }
    }

    addTitleToSection(sectionObj, TITLE.FORMAT.SPACER, TITLE.SOURCE.NONE, TITLE.ID.NONE, "", font.VARIANT.H5, "", "","");
    addSectionToPage(pageObj, sectionObj);
}

export function checkQuestionnaireForPageHeaderOrFootersAddSection(pageObj, qnr, objPrefix) {
    // QQQQQ YYYYY
    const titlePositionArray = ["Left", "Center", "Right"];

    const fs = {
        "pageHeader": {format: TITLE.FORMAT.PAGE_HEADER, source: TITLE.SOURCE.QNR_METADATA_PAGE_HEADER},
        "pageFooter1": {format: TITLE.FORMAT.PAGE_FOOTER1, source: TITLE.SOURCE.QNR_METADATA_PAGE_FOOTER1},
        "pageFooter2": {format: TITLE.FORMAT.PAGE_FOOTER2, source: TITLE.SOURCE.QNR_METADATA_PAGE_FOOTER2},
    }

    if (tag.metadataExists(qnr, portalShared.CUSTOM_QNR_MAIN_PAGES_OBJ)) {

        let obj = tag.metadataValueObj(qnr, portalShared.CUSTOM_QNR_MAIN_PAGES_OBJ);

        for (let i = 0; i < titlePositionArray.length; i++) {
            let type = obj[objPrefix + titlePositionArray[i] + "Type"];
            let textObj = obj[objPrefix + titlePositionArray[i] + "TextObj"];
            // If an image, then textObj will be null.  Images are stored in their own metadata as a Base64 encoded string
            if ((type === "image") || ((textObj) && (textObj.title !== ""))) {

                let sectionObj = createPageSection(SECTION.JUSTIFY.CENTER, SECTION.ALIGN_ITEMS.STRETCH, SECTION.STYLE_TYPE.ONE, SECTION.CLASS_TYPE.CONTROL);

                addTitleToSection(sectionObj, fs[objPrefix].format, fs[objPrefix].source, qnr.id, TITLE.NAME.USE_SOURCE, font.VARIANT.SUBTITLE1, "", "", "");
                addSectionToPage(pageObj, sectionObj);
                break; // If any header or footer then only need to add one section
            }
        }
    }
}


export function checkObjForTitlesAndAddSection(pageObj, iObjType, iObj, qNumObj, showGroupKey, showGroupObjArray = []) {

    let titleAdded = false;

    let sectionObj = createPageSection(SECTION.JUSTIFY.CENTER, SECTION.ALIGN_ITEMS.STRETCH, SECTION.STYLE_TYPE.ONE, SECTION.CLASS_TYPE.CONTROL);

    // If object, Questionnaire, category, Group or Question is declared as a TITLE_BAR then create, and text will be from metadata
    // NOTE: If category object and is a title bar
    if (iObjType === OBJ_TYPE.CATEGORY && tag.metadataExistsObj(iObj, portalShared.CUSTOM_DDO_CAT_FORMAT)) {
        let customObj = tag.metadataValueObj(iObj, portalShared.CUSTOM_DDO_CAT_FORMAT);

        if (customObj.titlebar) {
            titleAdded = true;
            addTitleToSection(sectionObj, TITLE.FORMAT.CATEGORY_TITLE_BAR, TITLE.SOURCE.CAT_METADATA_TITLE_BAR, iObj.id, TITLE.NAME.USE_SOURCE, font.VARIANT.H2, "", showGroupKey, showGroupObjArray);
            addTitleToSection(sectionObj, TITLE.FORMAT.SPACER, TITLE.SOURCE.NONE, TITLE.ID.NONE, TITLE.NAME.USE_SOURCE, font.VARIANT.H2, "", showGroupKey, showGroupObjArray);
        }
    }
    else {


        let qNumFlag = ((iObjType === OBJ_TYPE.QUESTION) || (iObjType === OBJ_TYPE.GROUP)); // Only group and question names can have a question number

        if (iObjType === OBJ_TYPE.QUESTION) {
            // If question is a LIKERT then the question text is rendered as part of the question object
            let customQuestionObj = {displayAs: "single"};
            if (tag.metadataExists(iObj, portalShared.CUSTOM_DDO_QUE_FORMAT)) {
                customQuestionObj = tag.metadataValueObj(iObj, portalShared.CUSTOM_DDO_QUE_FORMAT);
            }
            if (customQuestionObj.displayAs !== "likert") {
                titleAdded = addTitleIfRequired(portalShared.CUSTOM_DDO_NAME, sectionObj, iObjType, iObj, OBJ_TEXT_TYPE.NAME, qNumFlag ? qNumObj : "", showGroupKey, showGroupObjArray, titleAdded);
                titleAdded = addTitleIfRequired(portalShared.CUSTOM_DDO_DESC, sectionObj, iObjType, iObj, OBJ_TEXT_TYPE.DESCRIPTION, qNumFlag ? qNumObj : "", showGroupKey, showGroupObjArray, titleAdded);
            }
        } else {
            // Questionnaire, Category and Group
            titleAdded = addTitleIfRequired(portalShared.CUSTOM_DDO_NAME, sectionObj, iObjType, iObj, OBJ_TEXT_TYPE.NAME, qNumObj, showGroupKey, showGroupObjArray, titleAdded);
            titleAdded = addTitleIfRequired(portalShared.CUSTOM_DDO_DESC, sectionObj, iObjType, iObj, OBJ_TEXT_TYPE.DESCRIPTION, qNumObj, showGroupKey, showGroupObjArray, titleAdded);
        }

    }

    if (iObjType === OBJ_TYPE.QUESTION) {
        titleAdded = true;
        addQuestionToSection(sectionObj, iObj, showGroupKey, showGroupObjArray, qNumObj);
    }

    // Don't add a section of all the titles are hidden, except a question
    if (titleAdded) {
        addSectionToPage(pageObj, sectionObj);
    }
}


export function addTitleIfRequired(tagName, sectionObj, iObjType, iObj, iTextType, qNumObj, showGroupKey, showGroupObjArray = [], titleAdded) {

    // ADD A TITLE ITEM to JSON OBJECT ie.. template NAME or DESCRIPTION
    // NOTE: variant can be a simple text like "h5" or if CUSTOM then an OBJECT

    // Array[0] = text: show | left | center | right | hide
    // Array[1] = icon: none | info | comment | default  --- comment and icon can only be used with text show or left
    // Array[2] = variant: default | 'h1' | 'h2' | 'h3'  | 'h4'  | 'h5'  | 'h6'  | 'subtitle1'  | 'subtitle2'  | 'body1'  | 'body2'  | 'caption'  | 'button'  | 'overline'

    let sourceText = getSourceText(iObjType, iTextType);
    let id = (iObjType === OBJ_TYPE.QUESTIONNAIRE ? TITLE.ID.NONE : iObj.id); // For a questionnaire we don't need the id, there is only one so no lookup needed
    let formatMode = "";
    let variant = ""; // variant can be simple text "h5" or custom text object
    let added = false;
    let hasDescription = false; // For Groups and Questions descriptions may need to be added after LIKERT titles


    // Look ahead if group or question template has a visible description then flag it so renderer knows to wait on likert labels
    if  ((iObjType === OBJ_TYPE.GROUP)||(iObjType === OBJ_TYPE.QUESTION)) {
        if (tagName === portalShared.CUSTOM_DDO_NAME) {
            if (iObj.description !== "") {
                // Check to se if description is hidden or custom, if hidden the hasDescription will be false.
                if (tag.metadataExists(iObj, portalShared.CUSTOM_DDO_DESC)) {
                    hasDescription = (tag.metadataValue(iObj, portalShared.CUSTOM_DDO_DESC) !== "hide");
                }
                else {
                    hasDescription = true;
                }
            }
        }
    }

    if (tag.metadataExists(iObj, tagName)) {

        let metadataValue = tag.metadataValue(iObj, tagName);

        if (typeof metadataValue === "object") {

            formatMode = TITLE.FORMAT.CUSTOM;
            variant = metadataValue;

            addTitleToSection(sectionObj, formatMode, sourceText, id, TITLE.NAME.USE_SOURCE, variant, questionNumber(qNumObj, showGroupKey), showGroupKey, showGroupObjArray, hasDescription);
            added = true;
        }
    }
    else
    {
        variant = font.getTextDefaultVariant(iObjType, iTextType);

        if (iTextType === OBJ_TEXT_TYPE.NAME) {
            if (tag.metadataExists(iObj, tagName)) {
                addTitleToSection(sectionObj, TITLE.FORMAT.CUSTOM, sourceText, id, TITLE.NAME.USE_SOURCE, variant, questionNumber(qNumObj, showGroupKey), showGroupKey, showGroupObjArray, hasDescription);
            } else {
                addTitleToSection(sectionObj, TITLE.FORMAT.PLAIN, sourceText, id, TITLE.NAME.USE_SOURCE, variant, questionNumber(qNumObj, showGroupKey), showGroupKey, showGroupObjArray, hasDescription);
            }
            added = true;
        }
        if (iTextType === OBJ_TEXT_TYPE.DESCRIPTION && iObj.description !== "") {
            addTitleToSection(sectionObj, TITLE.FORMAT.CUSTOM, sourceText, id, TITLE.NAME.USE_SOURCE, variant, questionNumber(qNumObj, showGroupKey), showGroupKey, showGroupObjArray, false);
            added = true;
        }
    }

    return (added || titleAdded);
}


// export function addTitleIfTagExists(tagName, sectionObj, iObjType, iObj, iTextType, showNumber, showGroupKey, showGroupObjArray = [], titleAdded) {
//
//     // Array[0] = text: show | left | center | right | hide
//     // Array[1] = icon: none | info | comment | default  --- comment and icon can only be used with text show or left
//     // Array[2] = variant: default | 'h1' | 'h2' | 'h3'  | 'h4'  | 'h5'  | 'h6'  | 'subtitle1'  | 'subtitle2'  | 'body1'  | 'body2'  | 'caption'  | 'button'  | 'overline'
//
//     let sourceText = getSourceText(iObjType, iTextType);
//     let id = (iObjType === OBJ_TYPE.QUESTIONNAIRE ? TITLE.ID.NONE : iObj.id);
//     let formatMode = "";
//     let variant = "";
//     let added = false;
//
//     if (tag.metadataExists(iObj, tagName)) {
//
//         let metadataValue = tag.metadataValue(iObj, tagName);
//
//         // if (tagName === portalShared.CUSTOM_QNR_START_PAGE_OBJ) {
//         //
//         // }
//         // else if (tagName === portalShared.CUSTOM_QNR_START_PAGE_OBJ) {
//         //
//         // }
//         // else
//         if (typeof metadataValue === "string") {
//
//             if (metadataValue && (metadataValue !== "hide")) {
//
//                 addTitleToSection(sectionObj, TITLE.FORMAT.PLAIN, sourceText, id, TITLE.NAME.USE_SOURCE, variant, showNumber, showGroupKey);
//                 added = true;
//             }
//         }
//         else if (typeof metadataValue === "object") {
//
//             formatMode = TITLE.FORMAT.CUSTOM;
//             variant = metadataValue;
//
//             addTitleToSection(sectionObj, formatMode, sourceText, id, TITLE.NAME.USE_SOURCE, variant, showNumber, showGroupKey, showGroupObjArray);
//             added = true;
//         }
//
//     }
//
//     return (added || titleAdded);
// }


export function convertFormatEntryToENUM(textEntered, formatEntered) {

    let format = TITLE.FORMAT.PLAIN;


    switch(textEntered) {
        case "center":
            format = TITLE.FORMAT.PLAIN_CENTER;
            break;
        case "right":
            format = TITLE.FORMAT.PLAIN_RIGHT;
            break;
        // no default
    }

    if (textEntered === "show") {

        switch(formatEntered) {
            case "info":
                format = TITLE.FORMAT.ICON;
                break;
            // case "comment":
            //     format = TITLE.FORMAT.COMMENT;
            //     break;
            // no default
        }
    }

    return format;
}


export function getSourceText(iObjType, iTextType) {

    let sourceText = "";

    switch(iObjType) {
        case OBJ_TYPE.QUESTIONNAIRE:
            switch(iTextType) {
                case OBJ_TEXT_TYPE.NAME:
                    sourceText = TITLE.SOURCE.QNR_NAME;
                    break;
                case OBJ_TEXT_TYPE.DESCRIPTION:
                    sourceText = TITLE.SOURCE.QNR_DESC;
                    break;
                case OBJ_TEXT_TYPE.START_PAGE_SCRIPT:
                    sourceText = TITLE.SOURCE.QNR_START_PAGE_SCRIPT;
                    break;
                case OBJ_TEXT_TYPE.START_PAGE_TEXT:
                    sourceText = TITLE.SOURCE.QNR_START_PAGE_TEXT;
                    break;
                case OBJ_TEXT_TYPE.CLOSE_PAGE_SCRIPT:
                    sourceText = TITLE.SOURCE.QNR_CLOSE_PAGE_SCRIPT;
                    break;
                case OBJ_TEXT_TYPE.CLOSE_PAGE_TEXT:
                    sourceText = TITLE.SOURCE.QNR_CLOSE_PAGE_TEXT;
                    break;
                // no default
            }
            break;
        case OBJ_TYPE.CATEGORY:
            switch(iTextType) {
                case OBJ_TEXT_TYPE.NAME:
                    sourceText = TITLE.SOURCE.CAT_NAME;  // 1st
                    break;
                // case OBJ_TEXT_TYPE.SCRIPT:
                //     sourceText = TITLE.SOURCE.CAT_SCRIPT; // 2nd Category Script default has the Text Bubble icon
                //     break;
                case OBJ_TEXT_TYPE.DESCRIPTION:
                    sourceText = TITLE.SOURCE.CAT_DESC; // 3rd Category Description default has the Text Info icon
                    break;

                // no default
            }
            break;
        case OBJ_TYPE.GROUP:
        case OBJ_TYPE.GROUP_LIKERT:
            switch(iTextType) {
                case OBJ_TEXT_TYPE.NAME:
                    sourceText = TITLE.SOURCE.GRP_NAME;
                    break;
                case OBJ_TEXT_TYPE.DESCRIPTION:
                    sourceText = TITLE.SOURCE.GRP_DESC;
                    break;
                // no default
            }
            break;
        case OBJ_TYPE.QUESTION:
            switch(iTextType) {
                case OBJ_TEXT_TYPE.NAME:
                    sourceText = TITLE.SOURCE.QUE_NAME;
                    break;
                case OBJ_TEXT_TYPE.DESCRIPTION:
                    sourceText = TITLE.SOURCE.QUE_DESC;
                    break;
                // no default
            }
            break;
        // no default
    }

    return sourceText;
}


export function getQuestionNameText(question, questionNumber = "") {

    let textObj = {text: "", fontSize: "", variant: "", custom: false, customObj: null, indent: "0px", icon: "", displaySize: "", CustomTopographyStyle: null};

    textObj.text = (questionNumber === "" || questionNumber === "0") ? question.name : (questionNumber + " " + question.name);
    textObj.variant = font.getTextDefaultVariant(OBJ_TYPE.QUESTION, OBJ_TEXT_TYPE.NAME);

    textObj.custom = tag.metadataExists(question, portalShared.CUSTOM_DDO_NAME);
    textObj.customObj = textObj.custom ? tag.metadataValue(question, portalShared.CUSTOM_DDO_NAME) : null;
    textObj.customObj = (textObj.customObj === "hide") ? textObj.customObj : textObj.customObj;
    if (textObj.custom) {
        if (textObj.customObj.fontSizing === "points") {
            textObj.displaySize = textObj.customObj.fontSizeEntry + "pt";
        }
        else if (textObj.customObj.fontSizing === "variant") {
            textObj.displaySize = font.convertVariantToPointsStr(textObj.variant)
        }
        textObj.CustomTopographyStyle = style.getCustomTextStyle(textObj.customObj);
    }

    textObj.fontSize = font.convertVariantToPointsStr(textObj.variant);

    textObj.indent = "0px"; //tag.metadataExists(question, portalShared.INDENT_LIKERT_QUESTION_NAME) ? tag.metadataValue(question, portalShared.INDENT_LIKERT_QUESTION_NAME) : "0px";

    let metadataValue = tag.metadataExists(question, portalShared.CUSTOM_DDO_NAME) ? tag.metadataValue(question, portalShared.CUSTOM_DDO_NAME) : "";

    if (typeof metadataValue === "object") {
        textObj.variant = metadataValue.variant;
        textObj.icon = "";  // TODO
    }
    else if (metadataValue !== "") {

        let parsedMetadataArray = metadataValue.split("|");

        if (parsedMetadataArray[0] === "hide") {
            textObj.text = "";
            textObj.variant = "subtitle1";
        }

        if (parsedMetadataArray.length > 1) {

            textObj.icon = parsedMetadataArray[1];
        }

        if (parsedMetadataArray.length > 2) {

            textObj.variant = parsedMetadataArray[2];
        }
    }

    return textObj;
}


export const questionNumber = (qNumObj, showGroupKey) => {

    let qNum = "";
    if (qNumObj !== "") {
        if (qNumObj.useQuestionNumbers) {
            if (showGroupKey === "") {
                qNumObj.currQuestionNumber += 1;
                qNum = qNumObj.currQuestionNumber + qNumObj.questionNumberAppend;
            }
            else {
                //qNumObj.currQuestionNumber -= 1;  // If a show group then need to back up one question number
                if (qNumObj.subnumberShowgroupQuestions !== "") {
                    qNumObj.currSubQuestionNumber = 0;
                    qNum = questionSubNumber(qNumObj, qNumObj.subnumberShowgroupQuestions);
                }
                //qNumObj.currQuestionNumber += 1;
            }
        }
    }
    return qNum;
};


export const questionSubNumber = (qNumObj, subNumber) => {

    let qSubNum = "";
    if (qNumObj !== "") {
        if (qNumObj.subnumberLikertQuestions !== "") {
            qNumObj.currSubQuestionNumber += 1;
            switch (subNumber) {
                case "a":
                    qSubNum = convertNumberToLetter(qNumObj.currSubQuestionNumber, false);
                    break;
                case "a.":
                    qSubNum = convertNumberToLetter(qNumObj.currSubQuestionNumber, false) + ".";
                    break;
                case "a)":
                    qSubNum = convertNumberToLetter(qNumObj.currSubQuestionNumber, false) + ")";
                    break;
                case "(a)":
                    qSubNum = "(" + convertNumberToLetter(qNumObj.currSubQuestionNumber, false) + ")";
                    break;
                case "#a":
                    qSubNum = qNumObj.currQuestionNumber + convertNumberToLetter(qNumObj.currSubQuestionNumber, false);
                    break;
                case "#a.":
                    qSubNum = qNumObj.currQuestionNumber + convertNumberToLetter(qNumObj.currSubQuestionNumber, false) + ".";
                    break;
                case "#a)":
                    qSubNum = qNumObj.currQuestionNumber + convertNumberToLetter(qNumObj.currSubQuestionNumber, false) + ")";
                    break;
                case "#(a)":
                    qSubNum = qNumObj.currQuestionNumber + "(" + convertNumberToLetter(qNumObj.currSubQuestionNumber, false) + ")";
                    break;
                case "A":
                    qSubNum = convertNumberToLetter(qNumObj.currSubQuestionNumber, true);
                    break;
                case "A.":
                    qSubNum = convertNumberToLetter(qNumObj.currSubQuestionNumber, true) + ".";
                    break;
                case "A)":
                    qSubNum = convertNumberToLetter(qNumObj.currSubQuestionNumber, true) + ")";
                    break;
                case "(A)":
                    qSubNum = "(" + convertNumberToLetter(qNumObj.currSubQuestionNumber, true) + ")";
                    break;
                case "#A":
                    qSubNum = qNumObj.currQuestionNumber + convertNumberToLetter(qNumObj.currSubQuestionNumber, true);
                    break;
                case "#A.":
                    qSubNum = qNumObj.currQuestionNumber + convertNumberToLetter(qNumObj.currSubQuestionNumber, true) + ".";
                    break;
                case "#A)":
                    qSubNum = qNumObj.currQuestionNumber + convertNumberToLetter(qNumObj.currSubQuestionNumber, true) + ")";
                    break;
                case "#(A)":
                    qSubNum = qNumObj.currQuestionNumber + "(" + convertNumberToLetter(qNumObj.currSubQuestionNumber, true) + ")";
                    break;
                case "#.#":
                    qSubNum = qNumObj.currQuestionNumber + "." + qNumObj.currSubQuestionNumber;
                    break;
                default:
                    qSubNum = "";
                    break;
            }
        }
    }
    return qSubNum;
};


export const convertNumberToLetter = (currSubQuestionNumber, uppercase) => {

    return uppercase ? String.fromCharCode(96 + currSubQuestionNumber).toUpperCase() : String.fromCharCode(96 + currSubQuestionNumber);
};


export const getAnswerOptions = (objectWithAnswers) => {

    // Answers can be from a group or question instance or template
    return (objectWithAnswers.answerOptions !== undefined) ? objectWithAnswers.answerOptions : objectWithAnswers.answerOptionTemplates;

};





//
// let qNumObj = {
//     useQuestionNumbers: tag.metadataExists(qi, portalShared.SHOW_QUESTION_NUMBERS),
//     questionNumberAppend: tag.metadataValue(qi, portalShared.SHOW_QUESTION_NUMBERS),   // none | . or )
//     restartNumbersOnEachPage: tag.metadataExists(qi, portalShared.RESTART_QUESTION_NUMBERS_EACH_PAGE),
//     subnumberLikertQuestions: tag.metadataExists(qi, portalShared.SUBNUMBER_LIKERT_QUESTIONS), // (Questionnaire) a or (a) or Xa or X.#   ...by default none
//     subnumberShowgroupQuestions: tag.metadataExists(qi, portalShared.SUBNUMBER_SHOWGROUP_QUESTIONS), // (Questionnaire) a or (a) or Xa or X.#  ...by default none
//     currQuestionNumber: 0
// };
