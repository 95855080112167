import React from 'react';
import { MdThumbUp, MdThumbDown, MdMoodBad, MdSentimentDissatisfied, MdSentimentNeutral, MdSentimentSatisfied, MdMood, MdStarBorder, MdStar  } from 'react-icons/md';
import * as tag from "../constants/customMetadataConstants";
import * as portalShared from "../shared/metadataConstantsAndUtilities"
import * as adminIcon from "../../constants/iconConstants";
import {styled} from "@mui/material/styles";
import {DEFAULT_ANSWER_ICON_COLOR_UNSELECTED, getOpacity} from "../shared/metadataConstantsAndUtilities";


// Constants
// -----------------------------------------------------------------------------------------------

export const IconThumbUp = MdThumbUp;
export const IconThumbDown = MdThumbDown;

export const IconStarEmpty = MdStarBorder;
export const IconStarFilled = MdStar;
//export const IconStarFilledHalf = MdStarHalf;

export const IconSentiment1 = MdMoodBad;
export const IconSentiment2 = MdSentimentDissatisfied;
export const IconSentiment3 = MdSentimentNeutral;
export const IconSentiment4 = MdSentimentSatisfied;
export const IconSentiment5 = MdMood;

// Metadata tag constants
export const ICON_POSITION = "icon-position";
export const ICON_TYPE = "icon-type";
export const ICON_COLOR = "icon-color";
export const ICON_TITLES = "icon-titles";

export const LIKERT_TITLES_ALL = "As title above";  // Thumbs, Stars and Faces
export const LIKERT_TITLES_LR = "As title above (LR) only";  // Stars and Faces
export const LIKERT_TITLES_LCR = "As title above (LCR) only)";  // Stars and Faces with odd number of answers
export const LIKERT_TITLES_HIDDEN = "Hidden";  // Thumbs, Stars and Faces
export const LIKERT_TITLES_EMBEDDED = "Embedded";  // Circles and Squares Answer Text 1 or 2 characters embedded in icon

// Position constants
export const POSITION_NONE = "None";
export const POSITION_BEFORE = "Before";
export const POSITION_AFTER = "After";
export const POSITION_ONLY = "Only";

// Position constants LIKERT
export const POSITION_ICON = "Icon";
export const POSITION_ICON_NO_TITLE = "Icon (No Title)";
export const POSITION_ICON_FILL = "Icon Fill";
export const POSITION_ICON_FILL_NO_TITLE = "Icon Fill (No Title)";

// Type constants
export const TYPE_THUMBS_UP = "Thumbs Up";
export const TYPE_THUMBS_DOWN = "Thumbs Down";
//export const TYPE_THUMBS_UP_DOWN = "Thumbs Up/Down";

export const TYPE_RADIO = "Radio";

export const TYPE_SQUARE = "Square";

export const TYPE_CIRCLE = "Circle";  // Circles
//export const TYPE_CIRCLE_MODIFIER_COLORS = "Colors";  // Multi-colored circles (Single Select or Pyramid)

export const TYPE_STAR_FILLED = "Star Filled";
export const TYPE_STAR = "Star";

export const TYPE_SENTIMENT_VERY_DISSATISFIED = "Very Dissatisfied";
export const TYPE_SENTIMENT_DISSATISFIED = "Dissatisfied";
export const TYPE_SENTIMENT_NEUTRAL = "Neutral";
export const TYPE_SENTIMENT_SATISFIED = "Satisfied";
export const TYPE_SENTIMENT_VERY_SATISFIED = "Very Satisfied";

// export const DEFAULT_ICON_COLOR_NOT_SELECTED = "#8A8A8A" //  '#7F7F7F';      //QQQQQQ "#7F7F7F";  // "#F0F0F0"; // "#D0D0D0"; // "#2F4F4F";  #8A8A8A
// export const DEFAULT_ICON_COLOR_SELECTED = "#2196F3"; // QQQQQQ"#2196f3";  // Default Light Blue (before it was "#2F4F4F";)
// export const DEFAULT_ICON_COLOR_UNSELECTED = "gray";


// It appears that a answerOption structure is different between templates and instances.
// An instance adds an extra level "metatdataItems".
// NOTE: Metadata JSON structure for an instance is different then for a template. (answerOption.metadata.metadataItems)
export function metadataAnswerOptionInstanceFormatToTemplateFormat(answerMetadata) {

    // Converted Metadata
    return (answerMetadata.metadataItems) !== undefined ? answerMetadata.metadataItems : answerMetadata;
}


// Metadata is stored in answer and question templates
// export function formatLabel(answerOption, mode = "STANDARD") {
//
//     let label = answerOption.optionText;
//
//     if (hasIcon(answerOption)) {
//         label = formatIcon(answerOption, mode);
//     }
//
//     return label;
// }


// Placeholder for surveyJs Icon Support
export function hasIcon(answerOption) {

    let hasIcon = false;

    let items = metadataAnswerOptionInstanceFormatToTemplateFormat(answerOption.metadata); // NOTE: Metadata JSON structure for an instance is different then for a template. (answerOption.metadata.metadataItems)

    // Check for icon position props in the metadata
    if (items.hasOwnProperty(ICON_POSITION)) {

        // and must have an icon selected
        if (items.hasOwnProperty(ICON_TYPE)) {

            switch(items[ICON_POSITION]) {
                case POSITION_BEFORE:
                case POSITION_AFTER:
                case POSITION_ONLY:
                case POSITION_ICON:
                    hasIcon = true;
                    break;
                default:
                    break;
            }
        }
    }

    return hasIcon;
}

// 0 is no icon, 1 = before, 2 = after and 3 = only
export function hasIconByPosition(metadata) {

    let hasIconPosition = 0;

    let items = metadataAnswerOptionInstanceFormatToTemplateFormat(metadata); // NOTE: Metadata JSON structure for an instance is different then for a template. (answerOption.metadata.metadataItems)

    // Check for icon position props in the metadata
    if (items.hasOwnProperty(ICON_POSITION)) {

        // and must have an icon selected
        if (items.hasOwnProperty(ICON_TYPE)) {

            switch(items[ICON_POSITION]) {
                case POSITION_BEFORE:
                    hasIconPosition = 1;
                    break;
                case POSITION_AFTER:
                    hasIconPosition = 2;
                    break;
                case POSITION_ONLY:
                    hasIconPosition = 3;
                    break;
                default:
                    break;
            }
        }
    }

    return hasIconPosition;
}


export function formatIcon(answerOption, mode = "STANDARD") {

    let formated = answerOption.optionText;

    let items = metadataAnswerOptionInstanceFormatToTemplateFormat(answerOption.metadata); // NOTE: Metadata JSON structure for an instance is different then for a template. (answerOption.metadata.metadataItems)

    // Check for icon position props in the metadata
    if (items.hasOwnProperty(ICON_POSITION)) {

        // and must have an icon selected
        if (items.hasOwnProperty(ICON_TYPE)) {

            let marginAdjustment = "";

            if (mode === "CHECKBOX") {
                marginAdjustment = "3px";
            }
            else {
                switch (items[ICON_TYPE]) {
                    case TYPE_THUMBS_UP:
                    case TYPE_THUMBS_DOWN:
                        marginAdjustment = "-5px";
                        break;
                    case TYPE_STAR:
                        marginAdjustment = "-10px";
                        break;
                    case TYPE_SENTIMENT_VERY_DISSATISFIED:
                    case TYPE_SENTIMENT_DISSATISFIED:
                    case TYPE_SENTIMENT_NEUTRAL:
                    case TYPE_SENTIMENT_SATISFIED:
                    case TYPE_SENTIMENT_VERY_SATISFIED:
                        marginAdjustment = "-5px";
                        break;
                    default:
                        marginAdjustment = "-10px";
                }
            }

            switch(items[ICON_POSITION]) {
                case POSITION_BEFORE:
                    formated = <span><div style={{display: "flex", alignItems: "center", marginTop: marginAdjustment}}>{iconType(answerOption)}<div style={{width: '10px'}}/><div style={{marginTop: '2px'}}>{formated}</div></div></span>;
                    break;
                case POSITION_AFTER:
                    formated = <span><div style={{display: "flex", alignItems: "center", marginTop: marginAdjustment}}><div>{formated}</div><div style={{width: '10px'}}/>{iconType(answerOption)}</div></span>;
                    break;
                case POSITION_ONLY:
                    formated = <span><div style={{display: "flex", alignItems: "center", marginTop: marginAdjustment}}>{iconType(answerOption)}</div></span>;
                    break;
                default:
                    break;
            }

        }
    }

    return formated;
}

// export function getButtonIcon(metadata) {
//
//     let items = metadataAnswerOptionInstanceFormatToTemplateFormat(metadata); // NOTE: Metadata JSON structure for an instance is different then for a template. (answerOption.metadata.metadataItems)
//
//     let iconName = items[ICON_TYPE];
//     let iconColor = items.hasOwnProperty(ICON_COLOR) ? items[ICON_COLOR] : '#2F4F4F' // QQQQQ "#2F4F4F"; // Default to black
//     let selectedIcon = "";
//
//     switch (iconName) {
//
//         case TYPE_THUMBS_UP:
//             selectedIcon = <IconThumbUp size="28" style={{color: iconColor}}/>;
//             break;
//         case TYPE_THUMBS_DOWN:
//             selectedIcon = <IconThumbDown size="28" style={{color: iconColor}}/>;
//             break;
//         case TYPE_STAR:
//             selectedIcon = <IconStarEmpty size="42" style={{color: iconColor}}/>;
//             break;
//         case TYPE_SENTIMENT_VERY_DISSATISFIED:
//             selectedIcon = <IconSentiment1 size="32" style={{color: iconColor}}/>;
//             break;
//         case TYPE_SENTIMENT_DISSATISFIED:
//             selectedIcon = <IconSentiment2 size="32" style={{color: iconColor}}/>;
//             break;
//         case TYPE_SENTIMENT_NEUTRAL:
//             selectedIcon = <IconSentiment3 size="32" style={{color: iconColor}}/>;
//             break;
//         case TYPE_SENTIMENT_SATISFIED:
//             selectedIcon = <IconSentiment4 size="32" style={{color: iconColor}}/>;
//             break;
//         case TYPE_SENTIMENT_VERY_SATISFIED:
//             selectedIcon = <IconSentiment5 size="32" style={{color: iconColor}}/>;
//             break;
//         default:
//             break;
//     }
//
//     return selectedIcon;
// }


export function fillMode(metadata) {

    let fillMode = false;

    switch(metadata[ICON_POSITION]) {
        case POSITION_ICON_FILL:
        case POSITION_ICON_FILL_NO_TITLE:
            fillMode = true;
            break;
        default:
            break;
    }
    return fillMode;
}

// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------
// LIKERT
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

// Look at first answer, whatever icon-type it is will determine all inputs for that likert
export function getLikertInputType(answerOptions) {

    let iconType = TYPE_RADIO; // radio is default type

    if (answerOptions.length > 0) {

        let md = metadataAnswerOptionInstanceFormatToTemplateFormat(answerOptions[0].metadata); // NOTE: Metadata JSON structure for an instance is different then for a template. (answerOption.metadata.metadataItems)

        if (md.hasOwnProperty(ICON_TYPE)) {

            iconType = md[ICON_TYPE];
        }
    }

    return iconType;
}


// export function useIconButtonsForLikertOld(answerOptions) {
//
//     let useIcons = true;
//
//     for (let i = 0; i < answerOptions.length; i++) {
//
//         let md = metadataAnswerOptionInstanceFormatToTemplateFormat(answerOptions[i].metadata); // NOTE: Metadata JSON structure for an instance is different then for a template. (answerOption.metadata.metadataItems)
//
//         // Must have an icon selected
//         if (md.hasOwnProperty(ICON_TYPE)) {
//             switch(md[ICON_POSITION]) {
//                 case POSITION_BEFORE:
//                 case POSITION_AFTER:
//                 case POSITION_ONLY:
//                 case POSITION_ICON:
//                 case POSITION_ICON_NO_TITLE:
//                 case POSITION_ICON_FILL:
//                 case POSITION_ICON_FILL_NO_TITLE:
//                     break;
//                 default:
//                     useIcons = false;
//                     break;
//             }
//         }
//         else {
//             useIcons = false;
//         }
//     }
//
//     return useIcons;
// }


// export function getLikertColumnLabel(answerOption) {
//
//     let label = answerOption.optionText;
//
//     let md = metadataAnswerOptionInstanceFormatToTemplateFormat(answerOption.metadata); // NOTE: Metadata JSON structure for an instance is different then for a template. (answerOption.metadata.metadataItems)
//
//     switch(md[ICON_POSITION]) {
//         case POSITION_ICON_NO_TITLE:
//         case POSITION_ICON_FILL_NO_TITLE:
//             label = "";
//             break;
//         default:
//             break;
//     }
//
//     return label;
// }

export function getLikertIconButton(savedObjs, answer, objId, onClickFunction, likertObj) {


    // Question level overrides

    let iconColor = portalShared.getColor(likertObj.unselectedIcon);
    let iconColorHover = portalShared.getColor(likertObj.hoverUnselectedIcon);

    let iconOpacity = portalShared.getOpacity(likertObj.unselectedIcon);
    let iconOpacityHover = portalShared.getOpacity(likertObj.hoverUnselectedIcon);

    let iconSelectedColor = portalShared.getColor(likertObj.selectedIcon);
    let iconSelectedColorHover = portalShared.getColor(likertObj.hoverSelectedIcon);

    let iconSelectedOpacity = portalShared.getOpacity(likertObj.selectedIcon);
    let iconSelectedOpacityHover = portalShared.getOpacity(likertObj.hoverSelectedIcon);


    // Answer metadata custom overrides take precedence to question level custom overrides

    let md = metadataAnswerOptionInstanceFormatToTemplateFormat(answer.metadata); // NOTE: Metadata JSON structure for an instance is different then for a template. (answerOption.metadata.metadataItems)

    let iconName = md[ICON_TYPE];  // Already checked and confirmed to have an icon-type

    let answerColorObj = null;
    if (md.hasOwnProperty(adminIcon.ICON_COLOR_OBJ)) {
        answerColorObj = JSON.parse(md[adminIcon.ICON_COLOR_OBJ]);

        iconSelectedColor = portalShared.getColor(answerColorObj.colorObj);
        iconSelectedColorHover = iconSelectedColor;
        iconSelectedOpacity = portalShared.getOpacity(answerColorObj.colorObj);
        iconSelectedOpacityHover = iconSelectedOpacity;
    }



    // iconColor = tag.metadataValue(answer, portalShared.ICON_UNSELECTED_COLOR, "", portalShared.DEFAULT_ANSWER_ICON_COLOR_UNSELECTED);
    // iconColorHover = tag.metadataValue(answer, portalShared.ICON_UNSELECTED_COLOR, "", portalShared.DEFAULT_ANSWER_ICON_COLOR_SELECTED);
    //
    // iconSelectedColor = tag.metadataValue(answer, portalShared.ICON_SELECTED_COLOR, "", portalShared.DEFAULT_ANSWER_ICON_COLOR_SELECTED);
    // iconSelectedColorHover = tag.metadataValue(answer, portalShared.ICON_SELECTED_COLOR, "", portalShared.DEFAULT_ANSWER_ICON_COLOR_SELECTED);
    // if (iconSelectedColor === portalShared.DEFAULT_ANSWER_ICON_COLOR_SELECTED) {
    //     iconSelectedColor = tag.metadataValue(answer, ICON_COLOR, "", portalShared.DEFAULT_ANSWER_ICON_COLOR_SELECTED);
    // }


    // Answer selected color overrides question level overrides
    // if (tag.metadataExistsObj(answer, adminIcon.ICON_COLOR_OBJ)) {
    //     let customAnswerObj = tag.metadataValueObj(answer, adminIcon.ICON_COLOR_OBJ);
    //     iconSelectedColor = portalShared.getColor(customAnswerObj.colorObj);
    //     iconSelectedOpacity = customAnswerObj.colorObj.opacity;
    // }

    let fillMode = false;
    let selectedAnswerIndex = -1;
    let selectedAnswerIcon = "";
    let selectedAnswerColor = iconSelectedColor;
    let selectedAnswerOpacity = iconSelectedOpacity;

    switch(md[ICON_POSITION]) {
        case POSITION_ICON_FILL:
        case POSITION_ICON_FILL_NO_TITLE:
            fillMode = true;
            break;
        default:
            break;
    }

    if (fillMode) {
        // look and see if any answer has been selected
        for (let i = 0; i < savedObjs.answers.length; i++) {
            if (savedObjs.answers[i].selected) {

                let tempMd = metadataAnswerOptionInstanceFormatToTemplateFormat(savedObjs.answers[i].metadata); // NOTE: Metadata JSON structure for an instance is different then for a template. (answerOption.metadata.metadataItems)
                selectedAnswerIndex = i;
                selectedAnswerIcon = tempMd[ICON_TYPE];
                if (tempMd.hasOwnProperty(ICON_COLOR)) {
                    selectedAnswerColor = tempMd[ICON_COLOR];
                }
                else {
                    selectedAnswerColor = iconSelectedColor;  // default selected color (Light Blue)
                }
            }
        }

        // if ((selectedAnswerIndex !== -1) && (selectedAnswerIcon === TYPE_STAR)) {
        //     selectedAnswerIcon = TYPE_STAR_FILLED;
        // }
    }


    // Get the index of where the current selected answer
    let currentAnswerIndex = -1;
    for (let i = 0; i < savedObjs.answers.length; i++) {
        if (savedObjs.answers[i].id === answer.id) {
            currentAnswerIndex = i;
        }
    }


    // LIKERT answer icon buttons are always black and get color if selected and specified
    if (selectedAnswerIndex !== -1 && (currentAnswerIndex <= selectedAnswerIndex)) {
        iconName = (selectedAnswerIcon === TYPE_STAR) ? TYPE_STAR_FILLED : selectedAnswerIcon;
        iconOpacity = selectedAnswerOpacity;
        iconColor = selectedAnswerColor;
    }
    else {
        if (answer.selected) {
            iconName = (iconName === TYPE_STAR) ? TYPE_STAR_FILLED : iconName; // Selected star icons display as filled

            // if (md.hasOwnProperty(ICON_COLOR)) {
            //     iconColor = md[ICON_COLOR];
            // }
            // else {
            iconColor = iconSelectedColor;
            // }
        }
    }

    const iconSize = 40;

    let iconImportName = "";

    let StyledIcon = null;

    switch (iconName) {
        case TYPE_THUMBS_UP:
            iconImportName = IconThumbUp;
            break;
        case TYPE_THUMBS_DOWN:
            iconImportName = IconThumbDown;
            break;
        case TYPE_STAR:
            iconImportName = IconStarEmpty;
            break;
        case TYPE_STAR_FILLED:
            iconImportName = IconStarFilled;
            break;
        case TYPE_SENTIMENT_VERY_DISSATISFIED:
            iconImportName = IconSentiment1;
            break;
        case TYPE_SENTIMENT_DISSATISFIED:
            iconImportName = IconSentiment2;
            break;
        case TYPE_SENTIMENT_NEUTRAL:
            iconImportName = IconSentiment3;
            break;
        case TYPE_SENTIMENT_SATISFIED:
            iconImportName = IconSentiment4;
            break;
        case TYPE_SENTIMENT_VERY_SATISFIED:
            iconImportName = IconSentiment5;
            break;
        default:
            break;
    }

    StyledIcon = styled(iconImportName)({
        color: answer.selected ? iconSelectedColor: iconColor,
        opacity: answer.selected ? iconSelectedOpacity: iconOpacity,
        "&:hover": {
            color: answer.selected ? iconSelectedColorHover: iconColorHover,
            opacity: answer.selected ? iconSelectedOpacityHover: iconOpacityHover,
        }
    })
    let icon = <StyledIcon size={iconSize} id={objId} onClick={onClickFunction}/>;

    return icon;
}


export function formatIconForLikert(metadata, index, isSelected, answers, iconsSelectedArray) {

    let icon = iconType({metadata: metadata}, 'LIKERT', isSelected, answers, true, index, iconsSelectedArray);

    return icon;
}


function iconType(answerOption, mode = 'STANDARD', isSelected = false, answers, isFillMode, index, iconsSelectedArray) {

    let items = metadataAnswerOptionInstanceFormatToTemplateFormat(answerOption.metadata); // NOTE: Metadata JSON structure for an instance is different then for a template. (answerOption.metadata.metadataItems)

    let selectedIconName = items[ICON_TYPE];  // Alredy checked and confirmed to have an icon-type
    let selectedIconColor = "#2F4F4F";

    // Get the selected icon
    let selectedIndex = index;

    // Only satisfaction icons swap out depending on selected icon during fill mode
    switch (selectedIconName) {

        case TYPE_SENTIMENT_VERY_DISSATISFIED:
        case TYPE_SENTIMENT_DISSATISFIED:
        case TYPE_SENTIMENT_NEUTRAL:
        case TYPE_SENTIMENT_SATISFIED:
        case TYPE_SENTIMENT_VERY_SATISFIED:
            if (mode === 'LIKERT') {
                let itemsSelected = metadataAnswerOptionInstanceFormatToTemplateFormat(answers[selectedIndex].metadata); // NOTE: Metadata JSON structure for an instance is different then for a template. (answerOption.metadata.metadataItems)

                if (iconsSelectedArray !== undefined) {

                    if (iconsSelectedArray.length !== 0) {

                        for (let i = 0; i < iconsSelectedArray.length; i++) {
                            if (iconsSelectedArray[i]) {
                                selectedIndex = i;
                            }
                        }
                    }

                    // Swap out icons for fill mode

                    if (isFillMode) {
                        if (index <= selectedIndex) {
                            selectedIconName = itemsSelected[ICON_TYPE];
                            selectedIconColor = itemsSelected.hasOwnProperty(ICON_COLOR) ? itemsSelected[ICON_COLOR] : "#2F4F4F";
                        }
                    }
                }
            }
            break;
        default:
            break;
    }


    let selectedIcon = "";
    let iconColor = "#2F4F4F"; // Default to black NOTE: LIKERT are always displayed as black, and any color is applied when selected (Black #000000)

    if (mode === 'STANDARD' && items.hasOwnProperty(ICON_COLOR)) {
        iconColor = items[ICON_COLOR];
    }

    if (mode === 'LIKERT' && isSelected && items.hasOwnProperty(ICON_COLOR)) {
        iconColor = items[ICON_COLOR];
    }

    if (mode === 'LIKERT' && isFillMode && isSelected && items.hasOwnProperty(ICON_COLOR) && (selectedIconName !== TYPE_STAR)) {
        iconColor = selectedIconColor;
    }

    switch (selectedIconName) {

        case TYPE_THUMBS_UP:
            selectedIcon = <IconThumbUp size="28" style={{marginTop: mode==='LIKERT' ? '0px' : '0px', color: iconColor}}/>;
            break;
        case TYPE_THUMBS_DOWN:
            selectedIcon = <IconThumbDown size="28" style={{marginTop: mode==='LIKERT' ? '0px' : '0px', color: iconColor}}/>;
            break;
        case TYPE_STAR:
            // Star icons always swap to star filled, if fill mode all will swap up to selected
            if (isSelected) {
                selectedIcon = <IconStarFilled size="42" style={{marginTop: mode==='LIKERT' ? '-10px' : '0px', color: iconColor}}/>;
            }
            else {
                selectedIcon = <IconStarEmpty size="42" style={{marginTop: mode==='LIKERT' ? '-10px' : '0px', color: iconColor}}/>;
            }
            break;
        case TYPE_SENTIMENT_VERY_DISSATISFIED:
            selectedIcon = <IconSentiment1 size="32" style={{marginTop: mode==='LIKERT' ? '-3px' : '0px', color: iconColor}}/>;
            break;
        case TYPE_SENTIMENT_DISSATISFIED:
            selectedIcon = <IconSentiment2 size="32" style={{marginTop: mode==='LIKERT' ? '-3px' : '0px', color: iconColor}}/>;
            break;
        case TYPE_SENTIMENT_NEUTRAL:
            selectedIcon = <IconSentiment3 size="32" style={{marginTop: mode==='LIKERT' ? '-3px' : '0px', color: iconColor}}/>;
            break;
        case TYPE_SENTIMENT_SATISFIED:
            selectedIcon = <IconSentiment4 size="32" style={{marginTop: mode==='LIKERT' ? '-3px' : '0px', color: iconColor}}/>;
            break;
        case TYPE_SENTIMENT_VERY_SATISFIED:
            selectedIcon = <IconSentiment5 size="32" style={{marginTop: mode==='LIKERT' ? '-3px' : '0px', color: iconColor}}/>;
            break;
        default:
            break;
    }

    return selectedIcon;
}




