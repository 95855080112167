import React, {Component} from "react";
import PropTypes from "prop-types";
import ArgoColorInput from "./ArgoColorInput";
import ArgoTextDisplayCustom from "../common/ArgoTextDisplayCustom";
import ArgoTextFieldHook from "./ArgoTextFieldHook";
import ArgoTextFieldSelectHook from "./ArgoTextFieldSelectHook";
import * as TEMPLATE from "../../constants/templateConstants";
import * as icon from "../../constants/iconConstants";
import * as metadataUtils from "../../utilities/metadata";
import * as portalShared from "../../portal/shared/metadataConstantsAndUtilities"
import {DEFAULT_GRAYBAR_COLOR} from "../../portal/shared/metadataConstantsAndUtilities";


const defaultOrCustomList = [
    {value: "default", text: "Default"},
    {value: "custom", text: "Custom"}
];


class ArgoCustomLikertInputStyling extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customObj: JSON.parse(props.template.metadata[props.customMetadataTag])
        };
    }

    setStateCallback = (stateObj) => {

        let objKeyArray = Object.keys(stateObj);

        if (objKeyArray !== "null") {
            this.setState(stateObj);
        }
    }

    onSelectChange = (event, fieldName) => {

        let tempCustomObj = portalShared.cloneObj(this.state.customObj);

        tempCustomObj.likertObj[fieldName] = event.target.value;

        this.setState({customObj: tempCustomObj}, () => {this.setMetadataValue(tempCustomObj)});
    };

    onTextChange = (event, fieldName) => {

        let tempCustomObj = portalShared.cloneObj(this.state.customObj);

        tempCustomObj.likertObj[fieldName] = event.target.value;

        this.setState({customObj: tempCustomObj}, () => {this.setMetadataValue(tempCustomObj)});
    };

    setColor = (fieldName, colorObj) => {

        let tempCustomObj = portalShared.cloneObj(this.state.customObj);

        tempCustomObj.likertObj[fieldName].colorType = colorObj.colorType;
        tempCustomObj.likertObj[fieldName].colorIndex = colorObj.colorIndex;
        tempCustomObj.likertObj[fieldName].colorEntry = colorObj.colorEntry;
        tempCustomObj.likertObj[fieldName].color = colorObj.color;
        tempCustomObj.likertObj[fieldName].opacity = colorObj.opacity;
        tempCustomObj.likertObj[fieldName].opacityEntry = colorObj.opacityEntry;

        this.setState({customObj: tempCustomObj}, () => {this.setMetadataValue(tempCustomObj)});
    };

    setMetadataValue = (customObj) => {

        let clonedTemplate = portalShared.cloneObj(this.props.template); // Prevent mutation errors

        clonedTemplate.metadata[this.props.customMetadataTag] = JSON.stringify(customObj);

        this.props.templateUpdateFunction(clonedTemplate, this.props.depth);
    };




    displayColorEntries = (name, label, namePrefix1, namePrefix2, index, opacityFlag = false) => {

        return <div style={{display: "flex", alignItems: "left", width: '100%'}}>

            <div>{this.getColorEntry(name, namePrefix1, label, index, 0, opacityFlag)}</div>

            <div>{this.getColorEntry(name, namePrefix2, "Hover " + label, index, 0, opacityFlag)}</div>

        </div>
    }


    getColorEntry = (name, prefixLowerCase, label, index, prefixIndex, opacityFlag) => {

        let fieldName = prefixLowerCase + name;

        return <div style={{display: "flex", alignItems: "left", width: '100%'}}>
            <ArgoColorInput mode="questionSingleSelect"
                            colorLabel={label + " " + name}
                            fieldName={fieldName}
                            index={index + prefixIndex}
                            initToObj={this.state.customObj.likertObj[fieldName]}
                            setColor={this.setColor.bind(this)}
                            opacityFlag={opacityFlag}
                            selectDefaultWidth={200}
            />
            <div style={{width: "80px"}}>&nbsp;</div>
        </div>

    }

    displayTextEntries = (name, labelPrefix, label, namePrefix1, namePrefix2, index) => {

        return <div style={{display: "flex", alignItems: "left", width: '100%'}}>

            <div>{this.getTextEntry(name, namePrefix1, labelPrefix + " " + label, index, 1)}</div>

            <div style={{width: "20px"}}>&nbsp;</div>

            <div>{this.getTextEntry(name, namePrefix2, labelPrefix + " " + "Hover " + label, index, 2)}</div>

        </div>
    }

    getTextEntry = (name, namePrefix, label, index, prefixIndex) => {

        let fieldName = namePrefix + name;

        if (name === "FontSize" || name === "BorderWidth") {

            return <div style={{display: "flex", alignItems: "left", width: '100%'}}>
                <ArgoTextFieldHook
                    fileName="ArgoCustomLikertInputStyling"
                    fieldName={fieldName}
                    index={index + prefixIndex}
                    type="number"
                    label={label}
                    value={this.state.customObj.likertObj[fieldName]}
                    onChange={this.onTextChange}
                    width={200}
                />
            </div>

        } else {
            // Font Style or Font Weight

            let selectList = (name === "FontStyle") ? portalShared.fontStyleList : portalShared.fontWeightList;

            return <div style={{display: "flex", alignItems: "left", width: '100%'}}>
                <ArgoTextFieldSelectHook
                    fileName="ArgoCustomLikertInputStyling"
                    fieldName={fieldName}
                    label={label}
                    value={this.state.customObj.likertObj[fieldName]}
                    onChange={this.onSelectChange}
                    width="200px"
                    menuItems={selectList}
                />
            </div>

        }
    }


    render() {

        let isSquareOrCircle = (this.props.inputType === icon.TYPE_SQUARE || this.props.inputType === icon.TYPE_CIRCLE);

        const metadataObj = (this.props.template && this.props.template.metadata) ? this.props.template.metadata : {};

        let propsObj = {
            fileName: "ArgoCustomLikertInputStyling",
            onTextChange: this.onTextChange,
            templateType: this.props.templateType,
            template: this.props.template,
            metadata: metadataObj,
            templateUpdateFunction: this.props.templateUpdateFunction,
            setStateCallbackFunction: this.setStateCallback,
            setCustomMetadataFunction: metadataUtils.setCustomMetadata,
            depth: this.props.questionDepth,
            customObj: null
        }

        return (
            <div style={{width: '100%', backgroundColor: portalShared.DEFAULT_LIGHT_GRAY_HEX, paddingLeft: "10px", paddingTop: "10px"}}>

                <ArgoTextFieldSelectHook
                    fileName="ArgoCustomLikertInputStyling"
                    fieldName="customLikertInputStyling"
                    label="Likert Input Style"
                    value={this.state.customObj.likertObj.customLikertInputStyling}
                    onChange={this.onSelectChange}
                    width="150px"
                    menuItems={defaultOrCustomList}
                />

                {this.state.customObj.likertObj.customLikertInputStyling === "custom" ?
                    <div style={{paddingLeft: "20px"}}>

                        {!isSquareOrCircle ?
                            <React.Fragment>
                                <div style={{display: "flex", alignItems: "left", width: '100%'}}>
                                    <div>{this.displayColorEntries("Icon", "Unselected", "unselected", "hoverUnselected", 0, true)}</div>
                                </div>

                                <div>&nbsp;</div>

                                <div style={{display: "flex", alignItems: "left", width: '100%'}}>
                                    <div>{this.displayColorEntries("Icon", "Selected", "selected", "hoverSelected", 1, true)}</div>
                                </div>
                            </React.Fragment> : ""
                        }

                        {isSquareOrCircle ?
                            <React.Fragment>
                                <div style={{display: "flex", alignItems: "left", width: '100%'}}>
                                    <div>{this.displayColorEntries("Border", "Unselected", "unselected", "hoverUnselected", 0)}</div>
                                </div>

                                <div>&nbsp;</div>

                                <div style={{display: "flex", alignItems: "left", width: '100%'}}>
                                    <div>{this.displayColorEntries("Border", "Selected", "selected", "hoverSelected", 1)}</div>
                                </div>

                                <div>&nbsp;</div>

                                <div style={{display: "flex", alignItems: "left", width: '100%'}}>
                                    <div>{this.displayColorEntries("Background", "Unselected", "unselected", "hoverUnselected", 2)}</div>
                                </div>

                                <div>&nbsp;</div>

                                <div style={{display: "flex", alignItems: "left", width: '100%'}}>
                                    <div>{this.displayColorEntries("Background", "Selected", "selected", "hoverSelected", 3)}</div>
                                </div>

                                <div>&nbsp;</div>

                                <div style={{display: "flex", alignItems: "left", width: '100%'}}>
                                    <div>{this.displayColorEntries("Text", "Unselected", "unselected", "hoverUnselected", 4)}</div>
                                </div>

                                <div>&nbsp;</div>

                                <div style={{display: "flex", alignItems: "left", width: '100%'}}>
                                    <div>{this.displayColorEntries("Text", "Selected", "selected", "hoverSelected", 5)}</div>
                                </div>

                                <div>&nbsp;</div>

                                <div style={{display: "flex", alignItems: "left", width: '100%'}}>
                                    <div>{this.displayTextEntries("BorderWidth", "Unselected", "Border Width", "unselected", "hoverUnselected", 6)}</div>
                                </div>

                                <div>&nbsp;</div>

                                <div style={{display: "flex", alignItems: "left", width: '100%'}}>
                                    <div>{this.displayTextEntries("BorderWidth", "Selected", "Border Width", "selected", "hoverSelected", 7)}</div>
                                </div>

                            </React.Fragment> : ""
                        }

                        {/*<div>Text Styling, Font Family, Font Size, Font Style, Font Weight for CIRCLES and SQUARES</div>*/}

                        {isSquareOrCircle ?
                            <div>
                                <ArgoTextDisplayCustom
                                    templateType={this.props.templateType}
                                    textEntryType={TEMPLATE.TEXT_TYPE.QUESTION.NAME}
                                    fieldName="unselectedFontFamily"
                                    fieldTextObjName="unselectedFontFamily"
                                    initToObj={this.props.initToObj}
                                    displayLabel="test"
                                    metadataPropsObj={this.props.propsObj}
                                    indent={false}
                                    showDisplay={false}
                                    showSize={false}
                                    showStyle={false}
                                    showWeight={false}
                                    showColor={false}
                                    showOpacity={false}
                                />

                                <div>&nbsp;</div>

                                <div style={{display: "flex", alignItems: "left", width: '100%'}}>
                                    <div>{this.displayTextEntries("FontSize", "Unselected", "Font Size", "unselected", "hoverUnselected", 6)}</div>
                                </div>

                                <div>&nbsp;</div>

                                <div style={{display: "flex", alignItems: "left", width: '100%'}}>
                                    <div>{this.displayTextEntries("FontSize", "Selected", "Font Size", "selected", "hoverSelected", 7)}</div>
                                </div>

                                <div>&nbsp;</div>

                                <div style={{display: "flex", alignItems: "left", width: '100%'}}>
                                    <div>{this.displayTextEntries("FontStyle", "Unselected", "Font Style", "unselected", "hoverUnselected", 8)}</div>
                                </div>

                                <div>&nbsp;</div>

                                <div style={{display: "flex", alignItems: "left", width: '100%'}}>
                                    <div>{this.displayTextEntries("FontStyle", "Selected", "Font Style", "selected", "hoverSelected", 9)}</div>
                                </div>

                                <div>&nbsp;</div>

                                <div style={{display: "flex", alignItems: "left", width: '100%'}}>
                                    <div>{this.displayTextEntries("FontWeight", "Unselected", "Font Weight", "unselected", "hoverUnselected", 10)}</div>
                                </div>

                                <div>&nbsp;</div>

                                <div style={{display: "flex", alignItems: "left", width: '100%'}}>
                                    <div>{this.displayTextEntries("FontWeight", "Selected", "Font Weight", "selected", "hoverSelected", 11)}</div>
                                </div>

                            </div> : ""
                        }

                    </div> : ""
                }

                <div>&nbsp;</div>

            </div>
        );
    }
}

ArgoCustomLikertInputStyling.defaultProps = {};

ArgoCustomLikertInputStyling.propTypes = {
    templateType: PropTypes.string,
    template: PropTypes.object,
    depth: PropTypes.number,
    customMetadataTag: PropTypes.string,
    templateUpdateFunction: PropTypes.func,
    inputType: PropTypes.string
};

export default ArgoCustomLikertInputStyling;

