import * as ssType from "./singleSelectTypes";
import * as tag from "./customMetadataConstants";
import * as portalShared from "../shared/metadataConstantsAndUtilities"


export function singleSelectDisplayAs(question, answers) {

    let singleSelectDisplayAs = ssType.SINGLE_SELECT_RADIO;  // Default to radio buttons

    let questionCustomObj = null;
    if (tag.metadataExistsObj(question, portalShared.CUSTOM_DDO_QUE_FORMAT)) {
        questionCustomObj = tag.metadataValueObj(question, portalShared.CUSTOM_DDO_QUE_FORMAT);

        if (questionCustomObj.displayAs === "likert") {
            singleSelectDisplayAs = ssType.SINGLE_SELECT_LIKERT;
        } else {
            switch(questionCustomObj.style) {
                case "radios":
                    singleSelectDisplayAs = ssType.SINGLE_SELECT_RADIO;
                    break;
                case "ddlb":
                    singleSelectDisplayAs = ssType.SINGLE_SELECT_DDLB;
                    break;
                case "standard":
                    singleSelectDisplayAs = ssType.SINGLE_SELECT_STANDARD_BUTTON;
                    break;
                case "clear":
                    singleSelectDisplayAs = ssType.SINGLE_SELECT_CLEAR_BUTTON;
                    break;
                case "outlined":
                    singleSelectDisplayAs = ssType.SINGLE_SELECT_OUTLINED_RADIO;
                    break;
            }
        }
    } else {
        // If no overrides then default behaviour is radio button if < 6 answers, otherwise DDLB
        if (answers.length < 6) {
            singleSelectDisplayAs = ssType.SINGLE_SELECT_RADIO;
        }
        else {
            singleSelectDisplayAs = ssType.SINGLE_SELECT_DDLB;
        }
    }

    return singleSelectDisplayAs;
}

