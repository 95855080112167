import Grid from '@mui/material/Grid';
import * as ddo from "../constants/customObjConstants";
import Typography from '@mui/material/Typography';
import React from "react";
import * as tag from "../constants/customMetadataConstants";
import * as portalShared from "../shared/metadataConstantsAndUtilities"


// NOTE: PortalStyle.js controls uses these definitions

export const DEFAULT_FONT_FAMILY  = "Roboto, Helvetica Neue, Helvetica, Arial, sans-serif"; // Default font family

export const VARIANT = {
    H1: 'h1',
    H2: 'h2',
    H3: 'h3',
    H4: 'h4',
    H5: 'h5',
    H6: 'h6',
    SUBTITLE1: 'subtitle1',
    SUBTITLE2: 'subtitle2',
    BODY1: 'body1',
    BODY2: 'body2',
    CAPTION: "caption",
    OVERLINE: "overline",
    BUTTON: "button"
}

export const FONTSIZE = {
    H1: '36pt',
    H2: '31pt',
    H3: '26pt',
    H4: '21pt',
    H5: '16pt',
    H6: '16pt',
    SUBTITLE1: '12pt',
    SUBTITLE2: '12pt',
    BODY1: '14pt',
    BODY2: '14pt',
    CAPTION: '12pt',
    OVERLINE: '10pt',
    BUTTON: '18pt',
    DDLB: '12pt'
}

export const ICONSIZE = {
    H1: '38pt',
    H2: '33pt',
    H3: '28pt',
    H4: '23pt',
    H5: '18pt',
    H6: '18pt',
    SUBTITLE1: '14pt',
    SUBTITLE2: '14pt',
    BODY1: '16pt',
    BODY2: '16pt',
    CAPTION: '14pt',
    OVERLINE: '12pt',
    BUTTON: '20pt',
    DDLB: '14pt'
}

export const ICONPADDING = {
    H1: '2px',
    H2: '2px',
    H3: '2px',
    H4: '2px',
    H5: '2px',
    H6: '2px',
    SUBTITLE1: '2px',
    SUBTITLE2: '2px',
    BODY1: '2px',
    BODY2: '2px',
    CAPTION: '2px',
    OVERLINE: '2px',
    BUTTON: '2px',
    DDLB: '2px'
}

// Fontweight ptions are:
/* Keyword values */
// font-weight: normal;
// font-weight: bold;
//
///* Keyword values relative to the parent */
// font-weight: lighter;
// font-weight: bolder;
//
// /* Numeric keyword values */
// font-weight: 100;
// font-weight: 200;
// font-weight: 300;
// font-weight: 400;// normal
// font-weight: 500;
// font-weight: 600;
// font-weight: 700;// bold
// font-weight: 800;
// font-weight: 900;

export const FONTWEIGHT = {
    H1: 'normal',
    H2: 'normal',
    H3: 'normal',
    H4: 'normal',
    H5: 'normal',
    H6: 'normal',
    SUBTITLE1: 'normal',
    SUBTITLE2: 'normal',
    BODY1: 'normal',
    BODY2: 'normal',
    CAPTION: 'normal',
    OVERLINE: 'normal',
    BUTTON: 'normal',
    DDLB: 'normal'
}


// Valid styles are "normal", "italic" and "oblique"
export const FONTSTYLE = {
    H1: 'normal',
    H2: 'normal',
    H3: 'normal',
    H4: 'normal',
    H5: 'normal',
    H6: 'normal',
    SUBTITLE1: 'normal',
    SUBTITLE2: 'normal',
    BODY1: 'normal',
    BODY2: 'italic',
    CAPTION: 'normal',
    OVERLINE: 'normal',
    BUTTON: 'normal',
    DDLB: 'normal'
}

// NOTE: Using opacity looks different then gray does on a non-white background 0, 0, 0 is black
export const FONTCOLOR = {
    H1: 'black',
    H2: 'black',
    H3: 'black',
    H4: 'black',
    H5: 'black',
    H6: 'rgba(0, 0, 0, 0.54)',
    SUBTITLE1: 'rgba(0, 0, 0, 0.54)',
    SUBTITLE2: 'dodgerblue',
    BODY1: 'black',
    BODY2: 'black',
    CAPTION: 'rgba(0, 0, 0, 0.54)',
    OVERLINE: 'rgba(0, 0, 0, 0.54)',
    BUTTON: 'black',
    DDLB: portalShared.DEFAULT_DODGER_BLUE_HEX,
}


export function validateVariantEntry(variantEntered) {

    let variant = VARIANT.BODY1;  // default variant if invalid variant entered

    switch (variantEntered) {
        case VARIANT.H1:
        case VARIANT.H2:
        case VARIANT.H3:
        case VARIANT.H4:
        case VARIANT.H5:
        case VARIANT.H6:
        case VARIANT.SUBTITLE1:
        case VARIANT.SUBTITLE2:
        case VARIANT.BODY1:
        case VARIANT.BODY2:
        case VARIANT.CAPTION:
        case VARIANT.OVERLINE:
        case VARIANT.BUTTON:
            variant = variantEntered
            break;
        // no default
    }


    return variant;
}

export function getFontObjFromVariant(variant) {

    let fontSize = FONTSIZE.BODY1;  // Default point size if invalid variant
    let fontStyle = FONTCOLOR.BODY1;  // Default point size if invalid variant
    let fontColor = FONTSTYLE.BODY1;  // Default point size if invalid variant

    switch (variant) {
        case VARIANT.H1:
            fontSize = FONTSIZE.H1;
            fontStyle = FONTSTYLE.H1;
            fontColor = FONTCOLOR.H1;
            break;
        case VARIANT.H2:
            fontSize = FONTSIZE.H2;
            fontStyle = FONTSTYLE.H2;
            fontColor = FONTCOLOR.H2;
            break;
        case VARIANT.H3:
            fontSize = FONTSIZE.H3;
            fontStyle = FONTSTYLE.H3;
            fontColor = FONTCOLOR.H3;
            break;
        case VARIANT.H4:
            fontSize = FONTSIZE.H4;
            fontStyle = FONTSTYLE.H4;
            fontColor = FONTCOLOR.H4;
            break;
        case VARIANT.H5:
            fontSize = FONTSIZE.H5;
            fontStyle = FONTSTYLE.H5;
            fontColor = FONTCOLOR.H5;
            break;
        case VARIANT.H6:
            fontSize = FONTSIZE.H6;
            fontStyle = FONTSTYLE.H6;
            fontColor = FONTCOLOR.H6;
            break;
        case VARIANT.SUBTITLE1:
            fontSize = FONTSIZE.SUBTITLE1;
            fontStyle = FONTSTYLE.SUBTITLE1;
            fontColor = FONTCOLOR.SUBTITLE1;
            break;
        case VARIANT.SUBTITLE2:
            fontSize = FONTSIZE.SUBTITLE2;
            fontStyle = FONTSTYLE.SUBTITLE2;
            fontColor = FONTCOLOR.SUBTITLE2;
            break;
        case VARIANT.BODY1:
            fontSize = FONTSIZE.BODY1;
            fontStyle = FONTSTYLE.BODY1;
            fontColor = FONTCOLOR.BODY1;
            break;
        case VARIANT.BODY2:
            fontSize = FONTSIZE.BODY2;
            fontStyle = FONTSTYLE.BODY2;
            fontColor = FONTCOLOR.BODY2;
            break;
        case VARIANT.CAPTION:
            fontSize = FONTSIZE.BODY2;
            fontStyle = FONTSTYLE.BODY2;
            fontColor = FONTCOLOR.BODY2;
            break;
        case VARIANT.OVERLINE:
            fontSize = FONTSIZE.OVERLINE;
            fontStyle = FONTSTYLE.OVERLINE;
            fontColor = FONTCOLOR.OVERLINE;
            break;
        case VARIANT.BUTTON:
            fontSize = FONTSIZE.BUTTON;
            fontStyle = FONTSTYLE.BUTTON;
            fontColor = FONTCOLOR.BUTTON;
            break;
        // no default
    }

    return {fontSize: fontSize, fontStyle: fontStyle, fontColor: fontColor};
}


export function convertVariantToPointsStr(variant) {

    let pointSize = FONTSIZE.BODY1;  // Default point size if invalid variant

    switch (variant) {
        case VARIANT.H1:
            pointSize = FONTSIZE.H1;
            break;
        case VARIANT.H2:
            pointSize = FONTSIZE.H2;
            break;
        case VARIANT.H3:
            pointSize = FONTSIZE.H3;
            break;
        case VARIANT.H4:
            pointSize = FONTSIZE.H4;
            break;
        case VARIANT.H5:
            pointSize = FONTSIZE.H5;
            break;
        case VARIANT.H6:
            pointSize = FONTSIZE.H6;
            break;
        case VARIANT.SUBTITLE1:
            pointSize = FONTSIZE.SUBTITLE1;
            break;
        case VARIANT.SUBTITLE2:
            pointSize = FONTSIZE.SUBTITLE2;
            break;
        case VARIANT.BODY1:
            pointSize = FONTSIZE.BODY1;
            break;
        case VARIANT.BODY2:
            pointSize = FONTSIZE.BODY2;
            break;
        case VARIANT.CAPTION:
            pointSize = FONTSIZE.BODY2;
            break;
        case VARIANT.OVERLINE:
            pointSize = FONTSIZE.OVERLINE;
            break;
        case VARIANT.BUTTON:
            pointSize = FONTSIZE.BUTTON;
            break;
        // no default
    }

    return pointSize;
}


export function getDefaultVariantForItem(itemType, variant) {

    let convertedVariant = "";

    switch (itemType) {
        case ddo.OBJ.TYPE.SECTION_ITEM.TITLE:
            convertedVariant = variant;
            break;
        case ddo.OBJ.TYPE.SECTION_ITEM.BUTTON:
            switch (variant) {
                case ddo.BUTTON.VARIANT.CONTAINED:
                    convertedVariant = "contained";
                    break;
                case ddo.BUTTON.VARIANT.OUTLINED:
                    convertedVariant = "outlined";
                    break;
                case ddo.BUTTON.VARIANT.TEXT:
                    convertedVariant = "text";
                    break;
                default:
                    convertedVariant = "text";
                    break;
            }
            break;
        default:
            break;
    }

    return convertedVariant;
}


export function getTextDefaultVariant(iObjType, iTextType) {

    let defaultVariant = VARIANT.BODY1;

    switch(iObjType) {
        case ddo.OBJ_TYPE.QUESTIONNAIRE:
            switch(iTextType) {
                case ddo.OBJ_TEXT_TYPE.NAME:
                    defaultVariant = VARIANT.H1;
                    break;
                case ddo.OBJ_TEXT_TYPE.DESCRIPTION:
                    defaultVariant = VARIANT.H5;
                    break;
                case ddo.OBJ_TEXT_TYPE.START_PAGE_SCRIPT:
                    defaultVariant = VARIANT.H5;
                    break;
                case ddo.OBJ_TEXT_TYPE.START_PAGE_TEXT:
                    defaultVariant = VARIANT.H4;
                    break;
                case ddo.OBJ_TEXT_TYPE.CLOSE_PAGE_SCRIPT:
                    defaultVariant = VARIANT.H5;
                    break;
                case ddo.OBJ_TEXT_TYPE.CLOSE_PAGE_TEXT:
                    defaultVariant = VARIANT.H4;
                    break;
                // no default
            }
            break;
        case ddo.OBJ_TYPE.CATEGORY:
            switch(iTextType) {
                case ddo.OBJ_TEXT_TYPE.NAME:
                    defaultVariant = VARIANT.H2;
                    break;
                case ddo.OBJ_TEXT_TYPE.SCRIPT:
                    defaultVariant = VARIANT.H5;
                    break;
                case ddo.OBJ_TEXT_TYPE.DESCRIPTION:
                    defaultVariant = VARIANT.H5;
                    break;
                // no default
            }
            break;
        case ddo.OBJ_TYPE.GROUP:
        case ddo.OBJ_TYPE.GROUP_LIKERT:
            switch(iTextType) {
                case ddo.OBJ_TEXT_TYPE.NAME:
                    defaultVariant = VARIANT.H3;
                    break;
                case ddo.OBJ_TEXT_TYPE.DESCRIPTION:
                    defaultVariant = VARIANT.H5;
                    break;
                // no default
            }
            break;
        case ddo.OBJ_TYPE.QUESTION:
            switch(iTextType) {
                case ddo.OBJ_TEXT_TYPE.NAME:
                    defaultVariant = VARIANT.H6;
                    break;
                case ddo.OBJ_TEXT_TYPE.DESCRIPTION:
                    defaultVariant = VARIANT.BODY1;
                    break;
                // no default
            }
            break;
        case ddo.OBJ_TYPE.ANSWER:
            switch(iTextType) {
                case ddo.OBJ_TEXT_TYPE.NAME:
                    defaultVariant = VARIANT.CAPTION;
                    break;
                // no default
            }
            break;
        case ddo.OBJ_TYPE.TITLE:
            switch(iTextType) {
                case ddo.OBJ_TEXT_TYPE.TITLE:
                    defaultVariant = VARIANT.CAPTION;
                    break;
                // no default
            }
            break;
        // no default

    }

    return defaultVariant;
}


export function getTextPropertiesDefaultObj(iObjType, iTextType) {

    let defaultObj = {
        objType: iObjType,
        textType: iTextType,
        variant: "",
        indent: "0px",
        fontFamily: DEFAULT_FONT_FAMILY,
        fontWeight: "",
        fontSize: "",
        fontStyle: "",
        colorType: "select",  // forces color to the the color property used
        color: "",
        colorEntry: "",
        textAlign: "left",
        opacity: "100%"
    };

    switch(iObjType) {
        case ddo.OBJ_TYPE.QUESTIONNAIRE:
            switch(iTextType) {
                case ddo.OBJ_TEXT_TYPE.NAME:
                    defaultObj.variant = VARIANT.H1;
                    defaultObj.fontSize = FONTSIZE.H1;
                    defaultObj.fontWeight = FONTWEIGHT.H1;
                    defaultObj.fontStyle = FONTSTYLE.H1;
                    defaultObj.color = FONTCOLOR.H1;
                    defaultObj.colorEntry = FONTCOLOR.H1;
                    break;
                case ddo.OBJ_TEXT_TYPE.DESCRIPTION:
                    defaultObj.variant = VARIANT.H5;
                    defaultObj.fontSize = FONTSIZE.H5;
                    defaultObj.fontWeight = FONTWEIGHT.H5;
                    defaultObj.fontStyle = FONTSTYLE.H5;
                    defaultObj.color = FONTCOLOR.H5;
                    defaultObj.colorEntry = FONTCOLOR.H5;
                    break;
                case ddo.OBJ_TEXT_TYPE.START_PAGE_SCRIPT:
                case ddo.OBJ_TEXT_TYPE.CLOSE_PAGE_SCRIPT:
                    defaultObj.variant = VARIANT.H5;
                    defaultObj.fontSize = FONTSIZE.H5;
                    defaultObj.fontWeight = FONTWEIGHT.H5;
                    defaultObj.fontStyle = FONTSTYLE.H5;
                    defaultObj.color = FONTCOLOR.H5;
                    defaultObj.colorEntry = FONTCOLOR.H5;
                    break;
                case ddo.OBJ_TEXT_TYPE.START_PAGE_TEXT:
                case ddo.OBJ_TEXT_TYPE.CLOSE_PAGE_TEXT:
                    defaultObj.variant = VARIANT.H4;
                    defaultObj.fontSize = FONTSIZE.H4;
                    defaultObj.fontWeight = FONTWEIGHT.H4;
                    defaultObj.fontStyle = FONTSTYLE.H4;
                    defaultObj.color = FONTCOLOR.H4;
                    defaultObj.colorEntry = FONTCOLOR.H4;
                    break;
                // no default
            }
            break;
        case ddo.OBJ_TYPE.CATEGORY:
            switch(iTextType) {
                case ddo.OBJ_TEXT_TYPE.NAME:
                    defaultObj.variant = VARIANT.H2;
                    defaultObj.fontSize = FONTSIZE.H2;
                    defaultObj.fontWeight = FONTWEIGHT.H2;
                    defaultObj.fontStyle = FONTSTYLE.H2;
                    defaultObj.color = FONTCOLOR.H2;
                    defaultObj.colorEntry = FONTCOLOR.H2;
                    break;
                // case ddo.OBJ_TEXT_TYPE.SCRIPT:
                //     defaultObj.variant = VARIANT.H5;
                //     defaultObj.fontSize = FONTSIZE.H5;
                //     defaultObj.fontWeight = FONTWEIGHT.H5;
                //     defaultObj.fontStyle = FONTSTYLE.H5;
                //     defaultObj.color = FONTCOLOR.H5;
                //     defaultObj.colorEntry = FONTCOLOR.H5;
                //     break;
                case ddo.OBJ_TEXT_TYPE.DESCRIPTION:
                    defaultObj.variant = VARIANT.H5;
                    defaultObj.fontSize = FONTSIZE.H5;
                    defaultObj.fontWeight = FONTWEIGHT.H5;
                    defaultObj.fontStyle = FONTSTYLE.H5;
                    defaultObj.color = FONTCOLOR.H5;
                    defaultObj.colorEntry = FONTCOLOR.H5;
                    break;
                // no default
            }
            break;
        case ddo.OBJ_TYPE.GROUP:
        case ddo.OBJ_TYPE.GROUP_LIKERT:
            switch(iTextType) {
                case ddo.OBJ_TEXT_TYPE.NAME:
                    defaultObj.variant = VARIANT.H3;
                    defaultObj.fontSize = FONTSIZE.H3;
                    defaultObj.fontWeight = FONTWEIGHT.H3;
                    defaultObj.fontStyle = FONTSTYLE.H3;
                    defaultObj.color = FONTCOLOR.H3;
                    defaultObj.colorEntry = FONTCOLOR.H3;
                    break;
                case ddo.OBJ_TEXT_TYPE.DESCRIPTION:
                    defaultObj.variant = VARIANT.H5;
                    defaultObj.fontSize = FONTSIZE.H5;
                    defaultObj.fontWeight = FONTWEIGHT.H5;
                    defaultObj.fontStyle = FONTSTYLE.H5;
                    defaultObj.color = FONTCOLOR.H5;
                    defaultObj.colorEntry = FONTCOLOR.H5;
                    break;
                case ddo.OBJ_TEXT_TYPE.LIKERT_TITLES:
                    defaultObj.variant = VARIANT.CAPTION;
                    defaultObj.fontSize = FONTSIZE.CAPTION;
                    defaultObj.fontWeight = FONTWEIGHT.CAPTION;
                    defaultObj.fontStyle = FONTSTYLE.CAPTION;
                    defaultObj.color = FONTCOLOR.CAPTION;
                    defaultObj.colorEntry = FONTCOLOR.CAPTION;
                    break;
                case ddo.OBJ_TEXT_TYPE.LIKERT_RESPONSIVE_LABELS:
                    defaultObj.variant = VARIANT.CAPTION;
                    defaultObj.fontSize = FONTSIZE.CAPTION;
                    defaultObj.fontWeight = FONTWEIGHT.CAPTION;
                    defaultObj.fontStyle = FONTSTYLE.CAPTION;
                    defaultObj.color = FONTCOLOR.CAPTION;
                    defaultObj.colorEntry = FONTCOLOR.CAPTION;
                    break;
                // no default
            }
            break;
        case ddo.OBJ_TYPE.QUESTION:
            switch(iTextType) {
                case ddo.OBJ_TEXT_TYPE.NAME:
                    defaultObj.variant = VARIANT.H6;
                    defaultObj.fontSize = FONTSIZE.H6;
                    defaultObj.fontWeight = FONTWEIGHT.H6;
                    defaultObj.fontStyle = FONTSTYLE.H6;
                    defaultObj.color = FONTCOLOR.H6;
                    defaultObj.colorEntry = FONTCOLOR.H6;
                    break;
                case ddo.OBJ_TEXT_TYPE.DESCRIPTION:
                    defaultObj.variant = VARIANT.BODY1;
                    defaultObj.fontSize = FONTSIZE.BODY1;
                    defaultObj.fontWeight = FONTWEIGHT.BODY1;
                    defaultObj.fontStyle = FONTSTYLE.BODY1;
                    defaultObj.color = FONTCOLOR.BODY1;
                    defaultObj.colorEntry = FONTCOLOR.BODY1;
                    break;
                case ddo.OBJ_TEXT_TYPE.LIKERT_TITLES:
                    defaultObj.variant = VARIANT.CAPTION;
                    defaultObj.fontSize = FONTSIZE.CAPTION;
                    defaultObj.fontWeight = FONTWEIGHT.CAPTION;
                    defaultObj.fontStyle = FONTSTYLE.CAPTION;
                    defaultObj.color = FONTCOLOR.CAPTION;
                    defaultObj.colorEntry = FONTCOLOR.CAPTION;
                    break;
                case ddo.OBJ_TEXT_TYPE.LIKERT_RESPONSIVE_LABELS:
                    defaultObj.variant = VARIANT.CAPTION;
                    defaultObj.fontSize = FONTSIZE.CAPTION;
                    defaultObj.fontWeight = FONTWEIGHT.CAPTION;
                    defaultObj.fontStyle = FONTSTYLE.CAPTION;
                    defaultObj.color = FONTCOLOR.CAPTION;
                    defaultObj.colorEntry = FONTCOLOR.CAPTION;
                    break;
                case ddo.OBJ_TEXT_TYPE.QUESTION_LABELS:
                    defaultObj.variant = VARIANT.CAPTION;
                    defaultObj.fontSize = FONTSIZE.CAPTION;
                    defaultObj.fontWeight = FONTWEIGHT.CAPTION;
                    defaultObj.fontStyle = FONTSTYLE.CAPTION;
                    defaultObj.color = FONTCOLOR.CAPTION;
                    defaultObj.colorEntry = FONTCOLOR.CAPTION;
                    break;
                // no default
            }
            break;
        case ddo.OBJ_TYPE.ANSWER:
            switch(iTextType) {
                case ddo.OBJ_TEXT_TYPE.NAME:
                    defaultObj.variant = VARIANT.CAPTION;
                    defaultObj.fontSize = FONTSIZE.CAPTION;
                    defaultObj.fontWeight = FONTWEIGHT.CAPTION;
                    defaultObj.fontStyle = FONTSTYLE.CAPTION;
                    defaultObj.color = FONTCOLOR.CAPTION;
                    defaultObj.colorEntry = FONTCOLOR.CAPTION;
                    break;
                // no default
            }
            break;
        case ddo.OBJ_TYPE.BUTTON:
            switch(iTextType) {
                case ddo.OBJ_TEXT_TYPE.NAME:
                    defaultObj.variant = VARIANT.BUTTON;
                    defaultObj.fontSize = FONTSIZE.BUTTON;
                    defaultObj.fontWeight = FONTWEIGHT.BUTTON;
                    defaultObj.fontStyle = FONTSTYLE.BUTTON;
                    defaultObj.color = FONTCOLOR.BUTTON;
                    defaultObj.colorEntry = FONTCOLOR.BUTTON;
                    break;
                // no default
            }
            break;
        case ddo.OBJ_TYPE.DDLB:
            switch(iTextType) {
                case ddo.OBJ_TEXT_TYPE.NAME:
                    defaultObj.variant = VARIANT.DDLB;
                    defaultObj.fontSize = FONTSIZE.DDLB;
                    defaultObj.fontWeight = FONTWEIGHT.DDLB;
                    defaultObj.fontStyle = FONTSTYLE.DDLB;
                    defaultObj.color = FONTCOLOR.DDLB;
                    defaultObj.colorEntry = FONTCOLOR.DDLB;
                    break;
                // no default
            }
            break;
        case ddo.OBJ_TYPE.TITLE:
            switch(iTextType) {
                case ddo.OBJ_TEXT_TYPE.TITLE:
                    defaultObj.variant = VARIANT.CAPTION;
                    defaultObj.fontSize = FONTSIZE.CAPTION;
                    defaultObj.fontWeight = FONTWEIGHT.CAPTION;
                    defaultObj.fontStyle = FONTSTYLE.CAPTION;
                    defaultObj.color = FONTCOLOR.CAPTION;
                    defaultObj.colorEntry = FONTCOLOR.CAPTION;
                    break;
                // no default
            }
            break;
        // no default

    }

    // How to convert PT to REM?
    // To convert pt to rem, you should know total size of 1 REM, default is 16px
    // Then, just apply formula: (pt * 1.3333343412075) / rem size
    // For example, with 16px size of 1rem, 12pt will be converted to: (12 * 1.3333343412075) / 16 = 1rem

    // let fontSizeRem = ((parseInt(defaultObj.fontSize.replace("pt","")) * 1.3333343412075) / 16).toFixed(4);


    return defaultObj;
}


export function getVariantPropertiesObj(variant) {

    let propsObj = {
        variant: variant,
        fontSize: "",
        fontWeight: "",
        fontStyle: "",
        color: "",
        iconSize: "16pt",
        iconPadding: "2px"
    };

    switch(variant) {
        case VARIANT.H1:
            propsObj.fontSize = FONTSIZE.H1;
            propsObj.fontWeight = FONTWEIGHT.H1;
            propsObj.fontStyle = FONTSTYLE.H1;
            propsObj.color = FONTCOLOR.H1;
            propsObj.iconSize = ICONSIZE.H1;
            propsObj.iconPadding = ICONPADDING.H1;
            break;
        case VARIANT.H2:
            propsObj.fontSize = FONTSIZE.H2;
            propsObj.fontWeight = FONTWEIGHT.H2;
            propsObj.fontStyle = FONTSTYLE.H2;
            propsObj.color = FONTCOLOR.H2;
            propsObj.iconSize = ICONSIZE.H2;
            propsObj.iconPadding = ICONPADDING.H2;
            break;
        case VARIANT.H3:
            propsObj.fontSize = FONTSIZE.H3;
            propsObj.fontWeight = FONTWEIGHT.H3;
            propsObj.fontStyle = FONTSTYLE.H3;
            propsObj.color = FONTCOLOR.H3;
            propsObj.iconSize = ICONSIZE.H3;
            propsObj.iconPadding = ICONPADDING.H3;
            break;
        case VARIANT.H4:
            propsObj.fontSize = FONTSIZE.H4;
            propsObj.fontWeight = FONTWEIGHT.H4;
            propsObj.fontStyle = FONTSTYLE.H4;
            propsObj.color = FONTCOLOR.H4;
            propsObj.iconSize = ICONSIZE.H4;
            propsObj.iconPadding = ICONPADDING.H4;
            break;
        case VARIANT.H5:
            propsObj.fontSize = FONTSIZE.H5;
            propsObj.fontWeight = FONTWEIGHT.H5;
            propsObj.fontStyle = FONTSTYLE.H5;
            propsObj.color = FONTCOLOR.H5;
            propsObj.iconSize = ICONSIZE.H5;
            propsObj.iconPadding = ICONPADDING.H5;
            break;
        case VARIANT.H6:
            propsObj.fontSize = FONTSIZE.H6;
            propsObj.fontWeight = FONTWEIGHT.H6;
            propsObj.fontStyle = FONTSTYLE.H6;
            propsObj.color = FONTCOLOR.H6;
            propsObj.iconSize = ICONSIZE.H6;
            propsObj.iconPadding = ICONPADDING.H6;
            break;
        case VARIANT.SUBTITLE1:
            propsObj.fontSize = FONTSIZE.SUBTITLE1;
            propsObj.fontWeight = FONTWEIGHT.SUBTITLE1;
            propsObj.fontStyle = FONTSTYLE.SUBTITLE1;
            propsObj.color = FONTCOLOR.SUBTITLE1;
            propsObj.iconSize = ICONSIZE.SUBTITLE1;
            propsObj.iconPadding = ICONPADDING.SUBTITLE1;
            break;
        case VARIANT.SUBTITLE2:
            propsObj.fontSize = FONTSIZE.SUBTITLE2;
            propsObj.fontWeight = FONTWEIGHT.SUBTITLE2;
            propsObj.fontStyle = FONTSTYLE.SUBTITLE2;
            propsObj.color = FONTCOLOR.SUBTITLE2;
            propsObj.iconSize = ICONSIZE.SUBTITLE2;
            propsObj.iconPadding = ICONPADDING.SUBTITLE2;
            break;
        case VARIANT.BODY1:
            propsObj.fontSize = FONTSIZE.BODY1;
            propsObj.fontWeight = FONTWEIGHT.BODY1;
            propsObj.fontStyle = FONTSTYLE.BODY1;
            propsObj.color = FONTCOLOR.BODY1;
            propsObj.iconSize = ICONSIZE.BODY1;
            propsObj.iconPadding = ICONPADDING.BODY1;
            break;
        case VARIANT.BODY2:
            propsObj.fontSize = FONTSIZE.BODY2;
            propsObj.fontWeight = FONTWEIGHT.BODY2;
            propsObj.fontStyle = FONTSTYLE.BODY2;
            propsObj.color = FONTCOLOR.BODY2;
            propsObj.iconSize = ICONSIZE.BODY2;
            propsObj.iconPadding = ICONPADDING.BODY2;
            break;
        case VARIANT.CAPTION:
            propsObj.fontSize = FONTSIZE.CAPTION;
            propsObj.fontWeight = FONTWEIGHT.CAPTION;
            propsObj.fontStyle = FONTSTYLE.CAPTION;
            propsObj.color = FONTCOLOR.CAPTION;
            propsObj.iconSize = ICONSIZE.CAPTION;
            propsObj.iconPadding = ICONPADDING.CAPTION;
            break;
        case VARIANT.OVERLINE:
            propsObj.fontSize = FONTSIZE.OVERLINE;
            propsObj.fontWeight = FONTWEIGHT.OVERLINE;
            propsObj.fontStyle = FONTSTYLE.OVERLINE;
            propsObj.color = FONTCOLOR.OVERLINE;
            propsObj.iconSize = ICONSIZE.OVERLINE;
            propsObj.iconPadding = ICONPADDING.OVERLINE;
            break;
        case VARIANT.BUTTON:
            propsObj.fontSize = FONTSIZE.BUTTON;
            propsObj.fontWeight = FONTWEIGHT.BUTTON;
            propsObj.fontStyle = FONTSTYLE.BUTTON;
            propsObj.color = FONTCOLOR.BUTTON;
            propsObj.iconSize = ICONSIZE.BUTTON;
            propsObj.iconPadding = ICONPADDING.BUTTON;
            break;
        // no default
    }

    return propsObj;
}


// ---------------------------------------------------------------------------------------------------------------------
// Default Totography Display
// ---------------------------------------------------------------------------------------------------------------------
export function displayDefaultTopographyDetails(pageNum, sectionNum, itemNum) {

    function getFontVariantAndPointsObj(iObjType, iTextType, text) {

        let htmlObj = "";
        let obj = null;
        let displayText = "";

        obj = getTextPropertiesDefaultObj(iObjType, iTextType);

        let fontObj = getFontObjFromVariant(VARIANT.BODY1);

        displayText = obj.variant + " - (" + obj.fontSize + ") (" + obj.fontWeight + ") (" + obj.fontStyle + ") (" + obj.color + ")";

        htmlObj = <div style={{width: '100%', display: "flex", alignItems: "center"}}>
            <div style={{float: "left"}}>
                <Typography fontSize={obj.fontSize} fontStyle={obj.fontStyle} color={obj.color}>{text}</Typography>
            </div>
            <div style={{float: "left", width: "20px"}}>&nbsp;</div>
            <div style={{float: "left"}}>
                <Typography fontSize={fontObj.fontSize} fontStyle={fontObj.fontStyle} color={fontObj.fontColor}>{displayText}</Typography>
            </div>
            <div style={{clear: "left"}}></div>
        </div>

        return htmlObj;
    }

    function getVariantObj(variant) {

        let htmlObj = "";
        let obj = null;
        let displayText = "";

        obj = getVariantPropertiesObj(variant);

        let fontObj = getFontObjFromVariant(VARIANT.BODY1);

        displayText = "(" + obj.fontSize + ") (" + obj.fontWeight + ") (" + obj.fontStyle + ") (" + obj.color + ")";

        htmlObj = <div style={{width: '100%', display: "flex", alignItems: "center"}}>
            <div style={{float: "left"}}>
                <Typography fontSize={obj.fontSize} fontStyle={obj.fontStyle} color={obj.color}>{obj.variant}</Typography>
            </div>
            <div style={{float: "left", width: "20px"}}>&nbsp;</div>
            <div style={{float: "left"}}>
                <Typography fontSize={fontObj.fontSize} fontStyle={fontObj.fontStyle} color={fontObj.fontColor}>{displayText}</Typography>
            </div>
            <div style={{clear: "left"}}></div>
        </div>

        return htmlObj;
    }

    let fontObjH3 = getFontObjFromVariant(VARIANT.H3);
    let fontObjH4 = getFontObjFromVariant(VARIANT.H3);

    return (
        <Grid key={"TitlePlain-" + pageNum + "-" + sectionNum + "-" + itemNum} item sm={12} style={{display: "inline"}}>
            <div>
                <Typography fontSize={fontObjH3.fontSize} fontStyle={fontObjH3.fontStyle}
                            color={fontObjH3.fontColor}>{"Survey Text Formats:"}</Typography>
                <Typography fontSize={fontObjH4.fontSize} fontStyle={fontObjH4.fontStyle}
                            color={fontObjH4.fontColor}>{"variant (size) (weight) (style) (color) description"}</Typography>
                <div>&nbsp;</div>
                {getFontVariantAndPointsObj(ddo.OBJ_TYPE.QUESTIONNAIRE, ddo.OBJ_TEXT_TYPE.NAME, "Questionnaire Name")}
                {getFontVariantAndPointsObj(ddo.OBJ_TYPE.QUESTIONNAIRE, ddo.OBJ_TEXT_TYPE.DESCRIPTION, "Questionnaire Description")}
                <div>&nbsp;</div>
                {getFontVariantAndPointsObj(ddo.OBJ_TYPE.QUESTIONNAIRE, ddo.OBJ_TEXT_TYPE.START_PAGE_SCRIPT, "Questionnaire Start Page Script")}
                {getFontVariantAndPointsObj(ddo.OBJ_TYPE.QUESTIONNAIRE, ddo.OBJ_TEXT_TYPE.START_PAGE_TEXT, "Questionnaire Start Page Text")}
                <div>&nbsp;</div>
                {getFontVariantAndPointsObj(ddo.OBJ_TYPE.QUESTIONNAIRE, ddo.OBJ_TEXT_TYPE.CLOSE_PAGE_SCRIPT, "Questionnaire Close Page Script")}
                {getFontVariantAndPointsObj(ddo.OBJ_TYPE.QUESTIONNAIRE, ddo.OBJ_TEXT_TYPE.CLOSE_PAGE_TEXT, "Questionnaire Close Page Text")}
                <div>&nbsp;</div>
                {getFontVariantAndPointsObj(ddo.OBJ_TYPE.CATEGORY, ddo.OBJ_TEXT_TYPE.NAME, "Category Name")}
                {getFontVariantAndPointsObj(ddo.OBJ_TYPE.CATEGORY, ddo.OBJ_TEXT_TYPE.DESCRIPTION, "Category Description")}
                {/*{getFontVariantAndPointsObj(ddo.OBJ_TYPE.CATEGORY, ddo.OBJ_TEXT_TYPE.SCRIPT, "Category Script")}*/}
                <div>&nbsp;</div>
                {getFontVariantAndPointsObj(ddo.OBJ_TYPE.GROUP, ddo.OBJ_TEXT_TYPE.NAME, "Group Name")}
                {getFontVariantAndPointsObj(ddo.OBJ_TYPE.GROUP, ddo.OBJ_TEXT_TYPE.DESCRIPTION, "Group Description")}
                <div>&nbsp;</div>
                {getFontVariantAndPointsObj(ddo.OBJ_TYPE.QUESTION, ddo.OBJ_TEXT_TYPE.NAME, "Question Name")}
                {getFontVariantAndPointsObj(ddo.OBJ_TYPE.QUESTION, ddo.OBJ_TEXT_TYPE.DESCRIPTION, "Question Description")}
                <div>&nbsp;</div>
                {getFontVariantAndPointsObj(ddo.OBJ_TYPE.ANSWER, ddo.OBJ_TEXT_TYPE.NAME, "Answer Name")}
                <div>&nbsp;</div>
                {getFontVariantAndPointsObj(ddo.OBJ_TYPE.TITLE, ddo.OBJ_TEXT_TYPE.TITLE, "Title")}

                <div>&nbsp;</div>
                <div>&nbsp;</div>

                <Typography fontSize={fontObjH3.fontSize} fontStyle={fontObjH3.fontStyle}
                            color={fontObjH3.fontColor}>{"Variant Properties Formats:"}</Typography>
                <Typography fontSize={fontObjH4.fontSize} fontStyle={fontObjH4.fontStyle}
                            color={fontObjH4.fontColor}>{"variant (size) (weight) (style) (color)"}</Typography>

                {getVariantObj(VARIANT.H1)}
                <div>&nbsp;</div>
                {getVariantObj(VARIANT.H2)}
                <div>&nbsp;</div>
                {getVariantObj(VARIANT.H3)}
                <div>&nbsp;</div>
                {getVariantObj(VARIANT.H4)}
                <div>&nbsp;</div>
                {getVariantObj(VARIANT.H5)}
                <div>&nbsp;</div>
                {getVariantObj(VARIANT.H6)}
                <div>&nbsp;</div>
                {getVariantObj(VARIANT.SUBTITLE1)}
                <div>&nbsp;</div>
                {getVariantObj(VARIANT.SUBTITLE2)}
                <div>&nbsp;</div>
                {getVariantObj(VARIANT.BODY1)}
                <div>&nbsp;</div>
                {getVariantObj(VARIANT.BODY2)}
                <div>&nbsp;</div>
                {getVariantObj(VARIANT.CAPTION)}
                <div>&nbsp;</div>
                {getVariantObj(VARIANT.OVERLINE)}
                <div>&nbsp;</div>
                {getVariantObj(VARIANT.BUTTON)}
                <div>&nbsp;</div>
            </div>
        </Grid>
    );
}


export function getDefaultOrCustomTextObj(question, defaultType, defaultFieldName, override = "") {

    let textObj = {
        type: "custom",
        fontObj: null
    };

    let questionTag = portalShared.ANSWER_TEXT_MATCH_QUESTION_NAME;
    let descTag = portalShared.ANSWER_TEXT_MATCH_QUESTION_DESC;

    // Allowed titles to use NAME or DESC custom
    if (override === "title") {
        questionTag = portalShared.USE_CUSTOM_QUESTION_FOR_TITLE;
        descTag = portalShared.USE_CUSTOM_DESC_FOR_TITLE;
    }

    // Allowed responisive labels to use NAME or DESC custom
    // if (override === "responsive-label") {
    //     questionTag = portalShared.USE_CUSTOM_QUESTION_FOR_RESPONISVE_LABEL;
    //     descTag = portalShared.USE_CUSTOM_DESC_FOR_RESPONISVE_LABEL;
    // }

    // Allows user to use set the answer text to match a custom name/desc, the vaule can be a fontsize override ie.. "14pt"
    if (tag.metadataExists(question, portalShared.CUSTOM_DDO_NAME) && tag.metadataExists(question, questionTag)) {
        textObj.fontObj = JSON.parse(tag.metadataValue(question, portalShared.CUSTOM_DDO_NAME));
        textObj.fontObj.fontSize = tag.metadataValue(question, portalShared.ANSWER_TEXT_MATCH_QUESTION_NAME, "",  textObj.fontObj.fontSize);  //
        textObj.fontObj.fontSize =  textObj.fontObj.fontSize !== "" ?  textObj.fontObj.fontSize :  textObj.fontObj.fontSizeEntry + "pt";
    }
    else if (tag.metadataExists(question, portalShared.CUSTOM_DDO_DESC) && tag.metadataExists(question, descTag)) {
        textObj.fontObj = JSON.parse(tag.metadataValue(question, portalShared.CUSTOM_DDO_DESC));
        textObj.fontObj.fontSize = tag.metadataValue(question, portalShared.ANSWER_TEXT_MATCH_QUESTION_DESC, "", textObj.fontObj.fontSize);
        textObj.fontObj.fontSize =  textObj.fontObj.fontSize !== "" ?  textObj.fontObj.fontSize :  textObj.fontObj.fontSizeEntry + "pt";
    }
    else if (tag.metadataExistsObj(question, portalShared.CUSTOM_DDO_QUE_FORMAT)) {
        let customObj = tag.metadataValueObj(question, portalShared.CUSTOM_DDO_QUE_FORMAT);
        if (customObj.hasOwnProperty("text")) {
            textObj.fontObj = customObj.text;
            textObj.fontObj.fontSize = customObj.text.fontSize;
            textObj.fontObj.fontSize =  textObj.fontObj.fontSize !== "" ?  textObj.fontObj.fontSize :  textObj.fontObj.fontSizeEntry + "pt";
        }
    }
    else {
        // If no custom overrides then use default answer text porperties

        textObj.type = "default";
        textObj.fontObj = getTextPropertiesDefaultObj(defaultType, defaultFieldName);
        textObj.fontObj.color = portalShared.DEFAULT_UNSELECTED_COLOR;
        textObj.fontObj.colorEntry = portalShared.DEFAULT_UNSELECTED_COLOR;
    }

    return textObj;
}
