import * as TEMPLATE from "../constants/templateConstants";


export const ACCORDION_BG_COLOR = "white";
export const ACCORDION_BG_COLOR_HOVER = "lightgray";
export const ICON_COLOR  = "black";
export const ICON_COLOR_HIDDEN  = "white";
export const ICON_COLOR_HOVER  = "white";
export const TEXT_COLOR_PRIMARY  = "black";
export const TEXT_COLOR_SECONDARY  = "darkgray";
export const TEXT_COLOR_ALERT  = "red";


// Accordion Init expander by seting an array of objects with templateKeys to false (ie.. expander closed)
export function presetExpander(templateKeys, templates, props = {}) {

    let tempExpanderObj = {};

    for (let i = 0; i < templateKeys.length; i++) {
        // Check props.expander to get value of open, this circumvents the expander re-mounting
        let open = false;
        if (props.hasOwnProperty("expander")) {
            open = props.expander.expander.hasOwnProperty(templateKeys[i]) ? props.expander.expander[templateKeys[i]].open : false;
        }
        if (templateKeys[i] === '') {
            tempExpanderObj[i] = {open: open, template: templates[i]}
        }
        else {
            tempExpanderObj[templateKeys[i]] = {open: open, template: templates[templateKeys[i]] || templates[i]};
        }
    }

    return tempExpanderObj;
}


// Accordion Init expander by seting an array of objects with templateKeys to false (ie.. expander closed)
export function initializeExpander(expander, templateChildrenKeys, setStateCallback) {
    let expanderObj = {...expander};

    if (templateChildrenKeys !== undefined) {
        if (Object.keys(expander).length !== templateChildrenKeys.length) {
            templateChildrenKeys.map((key) => {
                expanderObj[key] = expander[key] ? expander[key] : false;
            });
        }
    }

    setStateCallback(expanderObj);
}


// Accordion expand/collable all toggle button
export function expandCollapseAll(templateType, expander, expandAll, templateKeys, hidePortalPreview, setStateCallback){
    let stateObj = {}
    let expanderObj = {};

    for (let i = 0; i < templateKeys.length; i++) {
        expanderObj[templateKeys[i]] = {open: expandAll, template: expander[templateKeys[i]].template}; // isExpanded = true, then collapse all, if false then expand all

        // If closed don't send to portal
        if (expanderObj[templateKeys[i]].open) {
            switch (templateType) {
                case TEMPLATE.TYPE.CATEGORY:
                    // portalShared.sendCategoryToPortal();
                    break;
                case TEMPLATE.TYPE.GROUP:
                    // portalShared.sendGroupToPortal();
                    break;
                case TEMPLATE.TYPE.QUESTION:
                    let tempTemplateObj = {...expanderObj[templateKeys[i]].template};
                    if (!hidePortalPreview) {
                        // portalShared.sendQuestionToPortal(tempTemplateObj, tempTemplateObj.id, tempTemplateObj.answerOptionTemplates);
                    }
                    break;
                // no default
            }
        }
    }

    stateObj = {expander: expanderObj, expandAll: !expandAll}

    setStateCallback(stateObj);
}

// Accordion expand/collable selected accordion
export function  expandCollapseSelected(expander, templateKey, setStateCallback) {
    let stateObj = {}
    let expandAll = true; // Default expandAll is true, but if expander is expanded then set to false

    let expanderObj = {...expander};

    if (expanderObj && (expanderObj.hasOwnProperty(templateKey))) {
        // NOTE: wrapped this if because of occasional errors on hasOwnProperty
        if (expanderObj[templateKey].hasOwnProperty("open")) {
            expanderObj[templateKey].open = !expander[templateKey].open;
        }
    }

    Object.keys(expanderObj).forEach(function(key) {
        if (expanderObj[key].open) {
            expandAll = false; // if any are expanded change button to Collapse All
        }
    });

    stateObj = {expander: expanderObj, expandAll: expandAll}

    setStateCallback(stateObj, templateKey);
}

// Used for when accordion is on the screen with the expand/collapse button
export function  expandCollapseAllCurrentScreen(expanderObj, currentExpandCollapseMode) {

    let expandAll = currentExpandCollapseMode;

    Object.keys(expanderObj).forEach(function(key) {
        expanderObj[key] = currentExpandCollapseMode;
    });

    return expanderObj;
}


export function expandCollapseMode(expanderObj) {

    let expandAll = true; // By default expandAll is true, but if expander is expanded the set to false

    Object.keys(expanderObj).forEach(function(key) {
        if (expanderObj[key]) {
            expandAll = false; // if any are expanded change button to Collapse All
        }
    });

    return expandAll;
}












