import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import InfoIcon from '@mui/icons-material/Info';
import {getQuestionTemplate} from "../../../actions/questionTemplateActions";
import ArgoChipHook from "../../common/ArgoChipHook";
import * as questionTypes from "../../../constants/questionTypeConstants";
import Typography from "@mui/material/Typography";
//import * as portal from "../../../utilities/portal";
import AnswerViewer from "../answers/AnswerViewer";
import statusFormatter from "../../../utilities/StatusFormatter";
import * as metadataUtils from "../../../utilities/metadata";
import * as TEMPLATE from "../../../constants/templateConstants";
import * as questionType from "../../../constants/questionTypeConstants";
import ArgoPortalPreview from "../../common/ArgoPortalPreview";
import * as portalShared from "../../../portal/shared/metadataConstantsAndUtilities";


class QuestionReview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialScroll: false
        };
    }

    componentDidMount() {
        if (this.props.templateKey || this.props.templateId) {
            this.props.getQuestionTemplate(this.props.templateKey !== "" ? this.props.templateKey : this.props.template.templateKey);
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (!this.state.initialScroll) {
            let page = document.getElementById(this.props.scrollTo);
            if (page) {
                page.scrollIntoView(false);
                this.setState({initialScroll: true});
            }
        }
    }

    render() {

        let template = {...this.props.template}; // Changed to a clone because some code was not working on QA the same as local


        let displayAsLikert = ""
        let metadataValueObj = null;
        let radioQuestionTypeDisplayModifier = "";
        if (template.questionType === questionTypes.RADIO) {
            // If question is of type RADIO hen see if it is being displayed as a LIKERT
            if (template.metadata.hasOwnProperty(portalShared.CUSTOM_DDO_QUE_FORMAT)) {
                metadataValueObj = JSON.parse(template.metadata[portalShared.CUSTOM_DDO_QUE_FORMAT]); // Convert value object string to JSON obj.
                if (metadataValueObj.hasOwnProperty("displayAs")) {
                    displayAsLikert = metadataValueObj["displayAs"]; // Convert value object string to JSON obj.
                    if (displayAsLikert === "likert") {
                        radioQuestionTypeDisplayModifier = "(Displayed aa a Likert scale)"
                    }
                }
            }
        }

        let reportingCategory = ""
        if (template.hasOwnProperty("metadata")) {
            if (template.metadata.hasOwnProperty(portalShared.KEY_METRIC_CATEGORY_QUESTION)) {
                reportingCategory = template.metadata[portalShared.KEY_METRIC_CATEGORY_QUESTION];
            }
        }

        let tags = template.searchTags || [];


        return (
            template.hasOwnProperty("metadata") ?
                <div style={{flexGrow: "2", padding: "32px"}}>

                    <div style={{minHeight: "min-content"}}>

                        <Typography variant="h6" component="div">
                            Question Information:
                        </Typography>

                        <div>&nbsp;</div>

                        <div id="QuestionReview-complex-question-type-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                            <div style={{color: "darkgray"}}>Status:</div>
                            <div>&nbsp;</div>
                            <div>{statusFormatter(this.props.template, 0, 0, true)}</div>
                        </div>

                        <div>&nbsp;</div>

                        <div id="QuestionReview-question-type-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                            <div style={{color: "darkgray"}}>Type:</div>
                            <div>&nbsp;</div>
                            <div>{questionTypes.getLabel[template.questionType] + " " + radioQuestionTypeDisplayModifier}</div>
                        </div>

                        <div>&nbsp;</div>

                        <div id="QuestionReview-question-name-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                            <div style={{color: "darkgray"}}>Question:</div>
                            <div>&nbsp;</div>
                            <div>{template.name}</div>
                        </div>

                        <div>&nbsp;</div>

                        <div id="QuestionReview-question-qualifier-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                            <div style={{color: "darkgray"}}>Qualifier:</div>
                            <div>&nbsp;</div>
                            <div>{template.nameQualifier}</div>
                        </div>

                        <div>&nbsp;</div>

                        <div id="QuestionReview-question-description-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                            <div style={{color: "darkgray"}}>Description:</div>
                            <div>&nbsp;</div>
                            <div>
                                {template.description && <div style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                                    <InfoIcon style={{minWidth: "36px"}}/>
                                    <div>&nbsp;</div>
                                    <div>{template.description}</div>
                                </div>}
                            </div>
                        </div>

                        <div>&nbsp;</div>

                        <div id="QuestionReview-key-metric-category-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                            <div style={{color: "darkgray"}}>Key Metric Category:</div>
                            <div>&nbsp;</div>
                            <div>{reportingCategory}</div>
                        </div>

                        <div>&nbsp;</div>

                        <div id="QuestionReview-optional-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                            <div style={{color: "darkgray"}}>Response:</div>
                            <div>&nbsp;</div>
                            <div style={{color: template.optional ? "black" : "red"}}>{template.optional ? "Optional" : "Required"}</div>
                        </div>

                        <div>&nbsp;</div>

                        <div id="QuestionReview-tags-label-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                            <div style={{color: "darkgray"}}>Assigned Tags:</div>
                            <div>&nbsp;</div>
                            <div>{tags.length === 0 ? " No assigned tags." : ""}</div>
                        </div>

                        <div>&nbsp;</div>

                        <div id="QuestionReview-tags-container-div" style={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center"}}>
                            {tags.map((tag, index) => {
                                return(<ArgoChipHook
                                        fileName="tag-lookup"
                                        fieldName={index.toString()}
                                        index={index}
                                        label={tag.name}
                                    />
                                )
                            })}
                        </div>

                        <div>&nbsp;</div>

                        <div id="QuestionReview-metadata-container-div">
                            {metadataUtils.listMetadata(this.props.template, "Question")}
                        </div>

                        {/*// ===========================================================================================================*/}
                        {/*// ANSWERS LIST */}
                        {/*// ===========================================================================================================*/}

                        {(this.props.template.answerOptionTemplates.length > 0) ?
                            <AnswerViewer
                                answerOptionTemplates={this.props.template.answerOptionTemplates}
                            />
                            : ""
                        }

                        {/*// ===========================================================================================================*/}
                        {/*// PORTAL PREVIEW */}
                        {/*// ===========================================================================================================*/}

                        {!this.props.hidePortalPreview  && this.props.template.questionType !== questionTypes.LIKERT_RADIO ?
                            <div>
                                <ArgoPortalPreview
                                    calledBy="QuestionReview"
                                    templateType={TEMPLATE.TYPE.QUESTION}
                                    template={this.props.template}
                                    answerOptionTemplates={this.props.answerOptionTemplates}
                                    hidePortalPreview={this.props.template.questionType === questionType.LIKERT_RADIO}
                                />
                            </div> : ""
                        }

                    </div>
                </div>
                : ""
        );
    }
}

QuestionReview.defaultProps = {
    scrollTo: ""
};

QuestionReview.propTypes = {
    templateKey: PropTypes.string,
    templateId: PropTypes.number,
    scrollTo: PropTypes.string
};

function mapStateToProps(state, props) {
    return {
        template: state.questionTemplate.hasOwnProperty(props.templateKey) ? state.questionTemplate[props.templateKey] : {},
        answerOptionTemplates: state.questionTemplate.hasOwnProperty(props.templateKey) ? state.questionTemplate[props.templateKey].answerOptionTemplates : {}
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getQuestionTemplate}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionReview);
