import TemplateApi from "../api/TemplateApi";
import * as types from "../constants/actionConstants";
import * as portalTypes from "../portal/constants/actionConstants";
import * as questionTypes from "../constants/questionTypeConstants";
import * as TEMPLATE from "../constants/templateConstants";


const initializeQuestionEditor = (template, depth = 0) => {
    let editableTemplate = {};
    let answerOptions = [];

    if (template) {
        editableTemplate = {
            templateKey: template.templateKey || "",
            name: template.name || "",
            nameQualifier: (template.nameQualifier)? template.nameQualifier : null,
            description: template.description || "",
            questionType: template.questionType || questionTypes.RADIO,
            optional: template.optional || true,
            preserveAnswer: template.preserveAnswer || true,
            metadata: template.metadata || {},
            searchTags: template.searchTags || [],
            id: template.id
        };
        answerOptions = (template.answerOptionTemplates)? template.answerOptionTemplates.filter((element) => {
            return element !== null;
        }) : [];
    }
    else {
        editableTemplate = {
            templateKey: "",
            name: "",
            nameQualifier: null,
            description: "",
            questionType: questionTypes.RADIO,
            optional: true, // Default new question to optional.
            preserveAnswer: true,
            answerOptionTemplates: [],
            searchTags: [],
            metadata: {}
        };
    }

    return {
        type: types.QUESTION_EDITOR_INITIALIZE,
        payload: {
            template: editableTemplate,
            answerOptionTemplates: answerOptions,
            options: {
                createMode: (template)? !template.templateKey : true,
                isEdit: true,
                hasDescription: (template) ? !!template.description : true,
                validate: [],
                isValidating: false,
                hasVersion: null,
                hasReference: null
            }
        },
        depth
    };
};

const updateQuestionTemplate = (template, depth = 0) => {
    return (dispatch) => {
        dispatch({type: types.QUESTION_EDITOR_UPDATE_TEMPLATE, payload: {template}, depth: depth});
        dispatch({type: portalTypes.INCREMENT_PORTAL_COUNT})
    };
};

const clearQuestionEditor = (depth = 0) => {
    return (dispatch) => {
        dispatch({type: types.QUESTION_EDITOR_CLEAR, depth: depth});
        dispatch({type: portalTypes.INCREMENT_PORTAL_COUNT})
    };
};

const clearQuestionTemplateSearchTags = (depth = 0) => {
    return (dispatch) => {
        dispatch({type: types.QUESTION_EDITOR_CLEAR_SEARCH_TAGS_ARRAY, depth: depth});
    };
};

const updateQuestionEditorSettings = (options, depth = 0) => {
    return {
        type: types.QUESTION_EDITOR_UPDATE_EDITOR,
        options,
        depth
    };
};

const validateQuestionNameAndQualifier = (templateType, name, qualifier, depth = 0) => {
    return (dispatch) => {
        dispatch({type: types.QUESTION_EDITOR_VALUE_VALIDATING, depth: depth});
        dispatch(validateQuestionNameAndQualifierUnique(templateType, name, qualifier, depth))
    };
};

const validateQuestionNameAndQualifierUnique = (name, qualifier, depth) => {

    let nameQualifier = qualifier === null ? "" : qualifier;

    let thunk = (dispatch) => {
        TemplateApi.checkTemplateNameAndQualifierExists(TEMPLATE.TYPE.QUESTION, name, nameQualifier).then((data) => {
            dispatch({type: types.QUESTION_EDITOR_VALUE_EXISTS, list: data.collection, depth: depth});
        });
    };

    thunk.meta = {
        debounce: {
            time: 1000,
            key: "__questionExistsCheck",
            leading: false,
            trailing: true
        }
    };

    return thunk;
};

const checkQuestionReference = (id, depth = 0) => {
    return (dispatch)=>{
        TemplateApi.getTemplateReferences(TEMPLATE.TYPE.QUESTION, id)
            .then((data) =>{
                dispatch({type: types.QUESTION_EDITOR_CHECK_REFERENCE, data, depth});
            });
    };
};

const checkQuestionVersion = (name, depth = 0) => {
    return (dispatch)=>{
        TemplateApi.checkQuestionExists({name})
            .then((data) =>{
                dispatch({type: types.QUESTION_EDITOR_CHECK_VERSION, data, depth});
            });
    };
};

export {
    initializeQuestionEditor,
    updateQuestionTemplate,
    clearQuestionEditor,
    updateQuestionEditorSettings,
    validateQuestionNameAndQualifierUnique,
    checkQuestionVersion,
    checkQuestionReference,
    clearQuestionTemplateSearchTags
};
