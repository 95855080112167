import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { PublicClientApplication } from '@azure/msal-browser';

// Msal Configurations
const config = {
  auth: {
    authority: 'https://login.microsoftonline.com/4ac6b7ee-ca4a-40de-a057-1f654d8bf873',
    clientId: '01bee8c8-e53b-420b-85f2-1594af754098',
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true
  }
};
 
// Authentication Parameters
const authenticationParameters = {
  scopes: [
    'user.read'
  ]
}
 
// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin 
}
 
export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)
export const msalInstance = new PublicClientApplication(config);