import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Link} from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableFooter from '@mui/material/TableFooter';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {openDrawer} from "../actions/drawerActions";
import {initGetTemplateList, getTemplateReferences} from "../actions/templateListActions";
import GridActions from "../components/dialogs/GridActions";
import ReferencesDrawer, {referencesDrawerProps} from "../components/drawers/ReferencesDrawer";
import ArgoListPagingControlsHook  from "../components/common/ArgoListPagingControlsHook";
import ArgoListSortColumn from "../components/common/ArgoListSortColumn";
import statusFormatter from "../utilities/StatusFormatter";
import gridPropIdFormatter from "../utilities/GridPropIdFormatter";
import {formatDateDisplay, toLocale} from '../utilities/dateFormats';
import {getIntegrationLogStatus, getIntegrationTypeName} from "../constants/integrationConstants";
import {QD_URL} from "../constants/systemConstants";
import * as TEMPLATE from "../constants/templateConstants";
import {getLabel} from "../constants/questionTypeConstants";
import {getGroupLabel} from "../constants/groupTypeConstants";
import style from "../blueTheme";


function wrapName(row, props, index, colnum) {
    if(!props.showIntegrationLogs) {
        let name = row.name;
        //Does the Title need to link to a review drawer, then set link ZZZZZ
        if (props.nameDrawer) {
            name = (<div
                onClick={props.nameDrawer.bind(null, row[props.uniqueIdentifier].toString(), row.id.toString(), row.name, row)}
                style={{
                    cursor: "pointer",
                    color: "rgb(33, 150, 243)",
                    maxWidth: '640px',
                    whiteSpace: 'nowrap',
                    wordWrap: 'break-word',
                    overflow: "hidden",
                    marginRight: '0',
                    textOverflow: 'ellipsis'
                }}
            >
                {row.name}
            </div>);
        }

        // if ((props.showQualifier)&&(row.nameQualifier !== undefined)&&(row.nameQualifier !== null)) {
        //     //qualifier = (<div style={{cursor: "pointer", color:"silver"}}>{row.nameQualifier}</div>); ZZZZZ
        // }

        return <TableCell id={setPropId(index, colnum)} title={row.name} colSpan="3"
                          style={{width: '30%'}}>{name}</TableCell>;
    }
    else {
        let name = JSON.parse(row.integrationEvent.message).EventName;
        if(props.nameDrawer) {
            name = (<div
                onClick={props.nameDrawer.bind(this, JSON.parse(row.integrationEvent.message).EventName, row.id)}
                style={{
                    cursor: "pointer",
                    color: "rgb(33, 150, 243)",
                    maxWidth: '315px',
                    whiteSpace: 'nowrap',
                    wordWrap: 'break-word',
                    overflow: "hidden",
                    marginRight: '0',
                    textOverflow: 'ellipsis'
                }}
            >
                {JSON.parse(row.integrationEvent.message).EventName}
            </div>);
        }

        return <TableCell id={gridPropIdFormatter(index, 2)} colSpan="3" sx={{fontSize: "9pt"}}>{name}</TableCell>;

    }
}


function formatRow(row, index, props) {

    let id = row[props.uniqueIdentifier];
    let colnum = props.showOutstanding ? 1 : 0;  // Need this to make title and status column count correct
    let name = props.showName ? wrapName(row, props, index, ++colnum) : "";
    let status = props.showStatus ? statusFormatter(row, index, ++colnum) : "";

    return (<TableRow hover key={"row-" + index + "-id-" + id} sx={{height: "25px"}} style={{borderBottom: '1px solid rgb(0 0 0 / 10%)'}}>
        {props.showOutstanding &&
            <TableCell id={setPropId(index, 1)} title={"Outstanding"} style={{width: '45px', textOverflow: 'hidden'}}>
                {row.isOutstandingDraft && <ErrorOutlineIcon style={style.tableGrid.warningIcon}/>}
            </TableCell>
        }
        {name}
        {props.showQualifier &&
            <TableCell id={setPropId(index, ++colnum)} title={row.nameQualifier} colSpan="2" style={{width: '15%'}}>
                <div style={{cursor: "default",
                    padding: "1px 10px",
                    width: '300px',
                    whiteSpace: 'nowrap',
                    wordWrap: 'break-word',
                    overflow: "hidden",
                    marginRight: '0',
                    textOverflow: 'ellipsis'}}>{row.nameQualifier}</div>
            </TableCell>
        }
        {status}
        {props.showType &&
            <TableCell id={setPropId(index, ++colnum)} title={props.type === 'question_template' ? getLabel[row.questionType] : row.type} colSpan="1" style={{padding: '0'}}>
                <div style={{cursor: "default",
                    padding: "0",
                    width: '100px',
                    whiteSpace: 'nowrap',
                    wordWrap: 'break-word',
                    overflow: "hidden",
                    marginRight: '0',
                    textOverflow: 'ellipsis'}}>{props.type === 'question_template' ? getLabel[row.questionType] : row.type}</div>
            </TableCell>
        }
        {props.showGroupType &&
        <TableCell id={setPropId(index, ++colnum)} title={getGroupLabel[row.questionGroupType]} colSpan="1" style={{padding: '0'}}>
            <div style={{cursor: "default",
                padding: "0",
                width: '100px',
                whiteSpace: 'nowrap',
                wordWrap: 'break-word',
                overflow: "hidden",
                marginRight: '0',
                textOverflow: 'ellipsis'}}>{getGroupLabel[row.questionGroupType]}</div>
        </TableCell>
        }
        {props.showLastUpdated &&
            <TableCell id={setPropId(index, ++colnum)} colSpan="2" style={{padding: '0 15px'}}>
                <div style={{cursor: "default",
                    padding: "1px 10px",
                    width: '70px',
                    whiteSpace: 'nowrap',
                    wordWrap: 'break-word',
                    overflow: "hidden",
                    marginRight: '0'}}>{toLocale(row.lastModifiedDate)}</div>
            </TableCell>
        }
        {props.showStartDate &&
            <TableCell id={setPropId(index, ++colnum)} colSpan="2" style={{padding: '0'}}>
                <div style={{cursor: "default",
                    padding: "1px 10px",
                    width: '80px',
                    whiteSpace: 'nowrap',
                    wordWrap: 'break-word',
                    overflow: "hidden",
                    marginRight: '0'}}>{toLocale(row.startDate)}</div>
            </TableCell>
        }
        {props.showEndDate &&
            <TableCell id={setPropId(index, ++colnum)} colSpan="2" style={{padding: '0'}}>
                <div style={{cursor: "default",
                    padding: "1px 10px",
                    width: '80px',
                    whiteSpace: 'nowrap',
                    wordWrap: 'break-word',
                    overflow: "hidden",
                    marginRight: '0'}}>{toLocale(row.endDate)}</div>
            </TableCell>
        }
        {props.showUpdatedBy &&
            <TableCell id={setPropId(index, ++colnum)} title={row.lastModifiedBy} colSpan="2" style={{padding: '0'}}>
                <div style={{cursor: "default",
                    padding: "1px 10px",
                    width: '90px',
                    whiteSpace: 'nowrap',
                    wordWrap: 'break-word',
                    overflow: "hidden",
                    marginRight: '0'}}>{row.lastModifiedBy}</div>
            </TableCell>
        }
        {props.showReferenceCount &&
            <TableCell id={setPropId(index, ++colnum)} colSpan="2">
                {<div onClick={viewReferences.bind(this, props, row)} style={{cursor: "pointer", color:"rgb(33, 150, 243)"}} >{row.numberOfReferencesCount}</div>}
            </TableCell>
        }
        {props.showInUseCount &&
            <TableCell id={setPropId(index, ++colnum)} colSpan="2" style={{padding: "1px 10px"}}>{row.inUseCount}</TableCell>
        }
        {props.showVersionCount &&
            <TableCell id={setPropId(index, ++colnum)} colSpan="2" style={{padding: "1px 10px"}}>
                <Link onClick={viewVersions.bind(this, props, row)} to={QD_URL + "/" + props.templateType + "-versions/" + row.templateKey} style={style.tableGrid.linkStyle}>{row.numberOfVersionsCount}</Link>
            </TableCell>
        }
        {props.showInUseAndVersionsSameColumn &&
            <TableCell id={setPropId(index, ++colnum)} colSpan="2" style={{padding: "1px 10px"}}>
                <div style={{cursor: "default",
                    padding: "1px 10px",
                    width: '100px',
                    whiteSpace: 'nowrap',
                    wordWrap: 'break-word',
                    overflow: "hidden",
                    marginRight: '0'}}>
                    {row.inUseCount + " / "}
                    <Link onClick={viewVersions.bind(this, props, row)} to={QD_URL + "/" + props.templateType + "-versions/" + row.templateKey} style={style.tableGrid.linkStyle}>{row.numberOfVersionsCount}</Link>
                </div>
            </TableCell>
        }
        {props.showReferencesAndVersionsSameColumn &&
            <TableCell id={setPropId(index, ++colnum)} colSpan="2" style={{padding: "0"}}>
                <div style={{cursor: "default", display: "flex", justifyContent: "left"}}>
                    <div onClick={viewReferences.bind(this, props, row)}
                         style={{cursor: "pointer",
                             color:"rgb(33, 150, 243)"}}>
                        {row.numberOfReferencesCount}
                    </div>
                    <div>{"\u00a0/\u00a0"}</div>
                    <Link onClick={viewVersions.bind(this, props, row)} to={QD_URL + "/" + props.templateType + "-versions/" + row.templateKey} style={style.tableGrid.linkStyle}>{row.numberOfVersionsCount}</Link>
                </div>
            </TableCell>
        }
        {props.showIntegrationType &&
            <TableCell id={setPropId(index, ++colnum)} colSpan="2" style={{padding: "1px 10px"}}>{getIntegrationTypeName[row.integrationType]}</TableCell>
        }
        {props.showNotes &&
            <TableCell id={setPropId(index, ++colnum)} title={row.commentsText} colSpan="1" style={{padding: "1px 10px"}}>{row.commentsText}</TableCell>
        }
        {props.showIntegrationLogs &&
            <TableCell id={gridPropIdFormatter(index, 1)} colSpan="3" sx={{fontSize: "9pt"}}>{formatDateDisplay(row.createdDate, "DATETIME")}</TableCell>
        }
        {props.showIntegrationLogs ? wrapName(row, props, index, colnum) : ""}
        {props.showIntegrationLogs &&
            <TableCell id={gridPropIdFormatter(index, 3)} colSpan="1" sx={{fontSize: "9pt"}}>{row.logServer}</TableCell>
        }
        {props.showIntegrationLogs &&
            <TableCell id={gridPropIdFormatter(index, 4)} colSpan="3" sx={{fontSize: "9pt"}}>{row.integrationEvent.questionnaire.questionnaireTemplate.name}</TableCell>}
        {props.showIntegrationLogs &&
            <TableCell id={gridPropIdFormatter(index, 5)} colSpan="1" sx={{fontSize: "9pt"}}>{getIntegrationLogStatus[row.success ? 1 : 0]}</TableCell>
        }
        {props.showIntegrationLogs &&
            <TableCell id={gridPropIdFormatter(index, 6)} colSpan="3" sx={{fontSize: "9pt"}}>{row.resultMessage}</TableCell>
        }
        {/*{(props.showIntegrationLogs && props.showActions) &&*/}
        {/*    <TableCell id={gridPropIdFormatter(index, 6)}>*/}
        {/*        <GridActions row={{id: row.id}}*/}
        {/*                     customActions={props.customActions}*/}
        {/*                     templateType={TEMPLATE.TYPE.INTEGRATION_LOG}*/}
        {/*                     templateMode={TEMPLATE.MODE.ALL}*/}
        {/*        />*/}
        {/*    </TableCell>*/}
        {/*}*/}

        {props.showActions &&
            <TableCell id={setPropId(index, ++colnum)} colSpan="2" style={{padding: "1px 10px"}}>
                <GridActions gridListType={props.gridListType}
                             row={props.isIntegration ? {id: row.id, templateKey: row.name} : row}
                             link={props.link}
                             customActions={props.customActions}
                             templateType={props.templateType}
                             templateMode={props.templateMode}
                             addVersionsAction={props.showGridActionVersions}
                             filter={props.filter}
                             defaultActions={props.defaultActions}
                             setLoadingState={props.setLoadingState}
                             savePortalReferenceJson={props.savePortalReferenceJson}
                             fileName={props.fileName}
                />
            </TableCell>
        }
    </TableRow>);
}

function setPropId(index, colnum) {
    return gridPropIdFormatter(index, colnum);
}

function viewReferences(props, row) {
    //debugger; // NOTE I DONT THINK THIS IS EVER CALLED
    props.getTemplateReferences(props.templateType, row.id);
    props.openDrawer(referencesDrawerProps, <ReferencesDrawer templateId={row.id} templateType={props.templateType} row={row} title={"This " + props.templateType + " is referenced in the following questionnaires"}/>);
}

function viewVersions(props, row) {
    props.initGetTemplateList(props.filter, props.templateType, TEMPLATE.MODE.VERSIONS, row.templateKey, row.name, row.nameQualifier, props.defaultActions);
    // <Link to={...}/> routes to version page
}

const TableGrid = (props, context) => {

    const {
        dataSource,
        tableStyle,
        showOutstanding,
        showName,
        showQualifier,
        showStatus,
        showType,
        showGroupType,
        showLastUpdated,
        showStartDate,
        showEndDate,
        showUpdatedBy,
        showInUseCount,
        showReferenceCount,
        showVersionCount,
        showInUseAndVersionsSameColumn,
        showReferencesAndVersionsSameColumn,
        showIntegrationType,
        showIntegrationLogs,
        showNotes,
        showActions
    } = props;

    let preparedTableStyle = {...style.tableGrid.wrapperStyle, ...tableStyle};

    //let qualifier = "-row-title";  //"TableGrid-row-" + index + "-col-" + colnum;

    //let colnum = props.showOutstanding ? 1 : 0;  // Need this to make title and status column count correct ZZZZZ


    return (
        <div style={{height: "100%"}}>

            <TableContainer id="TableGrid-TableCotainer" component={Paper} sx={{height: "100%"}}>
                <Table id="TableGrid-Table"
                       size="small"
                       wrapperstyle={preparedTableStyle}
                       headerstyle={style.tableGrid.headerStyle}
                       bodystyle={style.tableGrid.bodystyle}
                       tablelayout="auto">
                    <TableHead id="TableGrid-TableHead">
                        <TableRow sx={{height: "30px"}}>
                            {showOutstanding &&
                                <TableCell  title="Outstanding"  style={{width: "24px"}}>
                                    <ArgoListSortColumn id="TableGrid-outstanding" onSortChange={props.onSortChange}
                                                        gridState={props.sortState}
                                                        title="Outstanding"
                                                        icon={<ErrorOutlineIcon style={{color:  "rgb(1, 87, 155)"}} />}/>
                                </TableCell>
                            }
                            {showName &&
                                <TableCell colSpan="3" style={{padding: "1px 10px"}}>
                                    <ArgoListSortColumn id="TableGrid-name" onSortChange={props.onSortChange} gridState={props.sortState} title="Name"/>
                                </TableCell>
                            }
                            {showQualifier &&
                                <TableCell colSpan="2" style={{width: "10%"}}>
                                    <ArgoListSortColumn id="TableGrid-qualifier"
                                                        onSortChange={props.onSortChange}
                                                        gridState={props.sortState}
                                                        title="Qualifier"
                                                        width={"150px"}
                                                        padding={"1px 10px"}
                                    />
                                </TableCell>
                            }
                            {showStatus &&
                                <TableCell colSpan="1" style={{padding: "1px"}}>
                                    <ArgoListSortColumn id="TableGrid-status" sortable={false} title="Status"/>
                                </TableCell>
                            }
                            {showType &&
                                <TableCell colSpan="1" style={{padding: "1px 1px"}}>
                                    <ArgoListSortColumn id="TableGrid-type" onSortChange={props.onSortChange} gridState={props.sortState} title="Type"/>
                                </TableCell>
                            }
                            {showGroupType &&
                            <TableCell colSpan="1" style={{padding: "1px 1px"}}>
                                <ArgoListSortColumn id="TableGrid-group-type" sortable={false} title="Type"/>
                            </TableCell>
                            }
                            {showLastUpdated &&
                                <TableCell colSpan="2" style={{padding: "1px 0px 1px 20px"}}>
                                    <ArgoListSortColumn id="TableGrid-last-updated" onSortChange={props.onSortChange} gridState={props.sortState} title="Last updated"/>
                                </TableCell>
                            }
                            {showStartDate &&
                                <TableCell colSpan="2" style={{padding: "1px 10px"}}>
                                    <ArgoListSortColumn id="TableGrid-start-date" onSortChange={props.onSortChange} gridState={props.sortState} title="Start date"/>
                                </TableCell>
                            }
                            {showEndDate &&
                                <TableCell colSpan="2" style={{padding: "1px 10px"}}>
                                    <ArgoListSortColumn id="TableGrid-end-date" onSortChange={props.onSortChange} gridState={props.sortState} title="End date"/>
                                </TableCell>
                            }
                            {showUpdatedBy &&
                                <TableCell colSpan="2" style={{padding: "1px 10px",
                                    width: '90px'}}>
                                    <ArgoListSortColumn id="TableGrid-updated-by" onSortChange={props.onSortChange} gridState={props.sortState} title="Updated by"/>
                                </TableCell>
                            }
                            {showReferenceCount &&
                                <TableCell colSpan="2" style={{padding: "1px 10px"}}>
                                    <ArgoListSortColumn id="TableGrid-references" sortable={false} title="References"/>
                                </TableCell>
                            }
                            {showInUseCount &&
                                <TableCell colSpan="2" style={{padding: "1px 10px"}}>
                                    <ArgoListSortColumn id="TableGrid-in-use-count" onSortChange={props.onSortChange} gridState={props.sortState} sortable={false} title="In use count"/>
                                </TableCell>
                            }
                            {showVersionCount &&
                                <TableCell colSpan="2" style={{padding: "1px 10px"}}>
                                    <ArgoListSortColumn id="TableGrid-versions" sortable={false} title="Versions"/>
                                </TableCell>
                            }
                            {showInUseAndVersionsSameColumn &&
                                <TableCell colSpan="2" style={{padding: "1px 0px"}}>
                                    <ArgoListSortColumn id="TableGrid-in-use-and-version" onSortChange={props.onSortChange} gridState={props.sortState} sortable={false} title="In use/Versions"/>
                                </TableCell>
                            }
                            {showReferencesAndVersionsSameColumn &&
                                <TableCell colSpan="2" style={{padding: "0"}}>
                                    <ArgoListSortColumn id="TableGrid-in-use-and-version" onSortChange={props.onSortChange} gridState={props.sortState} sortable={false} title="References/Versions"/>
                                </TableCell>
                            }
                            {showIntegrationType &&
                                <TableCell colSpan="2" style={{padding: "1px 10px"}}>
                                    <ArgoListSortColumn id="TableGrid-integration-type" sortable={false} title="Type"/>
                                </TableCell>
                            }
                            {showNotes &&
                                <TableCell colSpan="1" style={{padding: "1px 10px"}}>
                                    <ArgoListSortColumn id="TableGrid-notes" onSortChange={props.onSortChange} gridState={props.sortState} sortable={false} title="Version count"/>
                                </TableCell>
                            }
                            {showIntegrationLogs &&
                                <TableCell colSpan="3" style={{padding: "1px 10px"}}>
                                    <ArgoListSortColumn id="TableGrid-IntegrationLogList-event-date" sortable={true} title="Event date"/>
                                </TableCell>
                            }
                            {showIntegrationLogs &&
                                <TableCell colSpan="3" style={{padding: "1px 10px"}}>
                                    <ArgoListSortColumn id="TableGrid-IntegrationLogList-event-name" sortable={true} title="Event name"/>
                                </TableCell>
                            }
                            {showIntegrationLogs &&
                                <TableCell colSpan="1" style={{padding: "1px 10px"}}>
                                    <ArgoListSortColumn id="TableGrid-IntegrationLogList-server" sortable={true} title="Server"/>
                                </TableCell>
                            }
                            {showIntegrationLogs &&
                                <TableCell colSpan="3" style={{padding: "1px 10px"}}>
                                    <ArgoListSortColumn id="TableGrid-IntegrationLogList-questionnaire" sortable={false} title="Questionnaire"/>
                                </TableCell>
                            }
                            {showIntegrationLogs &&
                                <TableCell colSpan="1" style={{padding: "1px 10px"}}>
                                    <ArgoListSortColumn id="TableGrid-IntegrationLogList-status" sortable={true} title="Status"/>
                                </TableCell>
                            }
                            {showIntegrationLogs &&
                                <TableCell colSpan="3" style={{padding: "1px 10px"}}>
                                    <ArgoListSortColumn id="TableGrid-IntegrationLogList-message" sortable={true} title="Message"/>
                                </TableCell>
                            }
                            {showActions &&
                                <TableCell colSpan="1" style={{padding: "1px 0px"}}>
                                    <ArgoListSortColumn id="TableGrid-actions" sortable={false} title="Actions"/>
                                </TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody
                        id="TableGrid-TableBody"
                    >
                        {dataSource && dataSource.map((questionnaire, index) =>
                            formatRow(questionnaire, index, props)
                        )}
                    </TableBody>

                    <TableFooter id="TableGrid-TableFooter" sx={{width: "100%"}}>
                        <TableRow sx={{width: "100%"}}>
                            <TableCell colSpan={20} style={{padding: "1px 10px"}}>
                                <div style={{width: "100%", marginLeft: "auto"}}>
                                    <ArgoListPagingControlsHook
                                        pageState={props.pageState}
                                        onPageChange={props.onPageChange}
                                        sx={{float: "right"}}
                                    />
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>

        </div>

    );
};


// TableGrid.contextTypes = {
//     muiTheme: PropTypes.object.isRequired
// };

// Defaults set for the main list pages (categories, groups, questions and actions) to display without any extra props to set
TableGrid.defaultProps = {
    showOutstanding: false,
    showName: true,
    showQualifier: true,
    showStatus: true,
    showType: false,
    showGroupType: false,
    showLastUpdated: true,
    showStartDate: false,
    showEndDate: false,
    showUpdatedBy: true,
    showInUseCount: false,
    showReferenceCount: false,
    showVersionCount: false,
    showInUseAndVersionsSameColumn: false,
    showReferencesAndVersionsSameColumn: false,
    showIntegrationType: false,
    showNotes: false,
    showActions: true,
    showGridActionVersions: true,
    showIntegrationLogs: false,
    uniqueIdentifier: "templateKey",
    fileName: ""
};

TableGrid.propTypes = {
    displayType: PropTypes.string,
    dataSource: PropTypes.array,
    gridListType: PropTypes.string,
    showOutstanding: PropTypes.bool,
    showName: PropTypes.bool,
    showQualifier: PropTypes.bool,
    showStatus: PropTypes.bool,
    showType: PropTypes.bool,
    showGroupType: PropTypes.bool,
    showLastUpdated: PropTypes.bool,
    showStartDate: PropTypes.bool,
    showEndDate: PropTypes.bool,
    showUpdatedBy: PropTypes.bool,
    showInUseCount: PropTypes.bool,
    showReferenceCount: PropTypes.bool,
    showVersionCount: PropTypes.bool,
    showInUseAndVersionsSameColumn: PropTypes.bool,
    showReferencesAndVersionsSameColumn: PropTypes.bool,
    showIntegrationType: PropTypes.bool,
    showNotes: PropTypes.bool,
    showActions: PropTypes.bool,
    showGridActionVersions: PropTypes.bool,
    showIntegrationLogs: PropTypes.bool,
    uniqueIdentifier: PropTypes.string,
    link: PropTypes.string,
    tableStyle: PropTypes.object,
    nameDrawer: PropTypes.func,
    customActions: PropTypes.array,
    templateType: PropTypes.string,
    templateMode: PropTypes.string,
    defaultActions: PropTypes.object,
    filter: PropTypes.object,
    pageState: PropTypes.object,
    setLoadingState: PropTypes.func,
    savePortalReferenceJson: PropTypes.func,
    fileName: PropTypes.string
};

function mapStateToProps(state, props) {
    return {
        filter: state.filter[TEMPLATE.FILTER[props.templateMode][props.templateType]] || {}  //Get correct filter from constant
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({initGetTemplateList, getTemplateReferences, openDrawer}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TableGrid);

